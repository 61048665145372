import { render, staticRenderFns } from "./SmashokuLabelPanel.vue?vue&type=template&id=996a883e&scoped=true&"
import script from "./SmashokuLabelPanel.vue?vue&type=script&lang=ts&"
export * from "./SmashokuLabelPanel.vue?vue&type=script&lang=ts&"
import style0 from "./SmashokuLabelPanel.vue?vue&type=style&index=0&id=996a883e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "996a883e",
  null
  
)

export default component.exports