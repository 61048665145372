import ObjectUtils from "@/utils/object-utils";
import {createDecimalRule, createMaxRule, createMinRule, requiredRule, typeNumberRule} from "@/utils/validation-rules";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";

export class CustomExportTemplateEntity {
  public id!:number;
  public name!: string;
  public template!: IFile;
  public outputExt!: CustomExportTemplateOutputExtension;
  public sortOrder!: number;

  constructor(init:Partial<CustomExportTemplateEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
    this.template = init.template || IFileDefaultValue;
  }

  public get outputExtLabel(): string {
    return CustomExportTemplateOutputExtensionDict[this.outputExt] || '';
  }
}

export enum CustomExportTemplateOutputExtension {
  PDF = 'pdf',
  Excel = 'xlsx',
  CSV = 'csv',
}
export const CustomExportTemplateOutputExtensionDict = {
  [CustomExportTemplateOutputExtension.PDF]: 'PDF',
  [CustomExportTemplateOutputExtension.Excel]: 'Excel',
  [CustomExportTemplateOutputExtension.CSV]: 'CSV',
};

export enum CustomExportTemplateResourceType {
  OwnSpec = 'own_spec',
  Product = 'product',
}

export const ValidatorRules:any = {
  template: [ requiredRule ],
  name: [requiredRule, createMaxRule(255, 'char')],
};
