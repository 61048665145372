


















import {Component, Prop} from 'vue-property-decorator';
import {ValidateWithScroll} from "@/utils/validation-handler";
import Vue from "vue";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: false, default: () => {} }) private rules!: object;
  @Prop({required: true}) private value!: boolean;
  @Prop({required: true}) private nameLabel!: string;
  @Prop({required: false}) private original!: { id: number, name: string };

  private get visible() { return this.value; }
  private set visible(val) { this.$emit('input', val) ; }

  private data = {
    id: null as number | null,
    name: '',
  };

  private get isUpdateMode() {
    return !!this.original;
  }
  private get buttonLabel() {
    return this.isUpdateMode ? this.$t('以上で保存') : this.$t('以上で作成');
  }
  private get title() {
    const action = this.isUpdateMode ? this.$t('編集') : this.$t('追加');
    return this.$t('common.of', { item: this.nameLabel, action: action });
  }
  private get description() {
    return `下記の「${this.buttonLabel}」ボタンを押すと、編集した${this.nameLabel}を保存します。`;
  }

  private created() {
    if (this.original) {
      this.data.id = this.original.id;
      this.data.name = this.original.name;
    }
  }

  private async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    this.$emit('save', this.data);
  }
}
