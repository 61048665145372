import { render, staticRenderFns } from "./ApprovalRequestStartModal.vue?vue&type=template&id=32509912&scoped=true&"
import script from "./ApprovalRequestStartModal.vue?vue&type=script&lang=ts&"
export * from "./ApprovalRequestStartModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32509912",
  null
  
)

export default component.exports