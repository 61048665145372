import {createMaxStringRule} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";
import isEqual from "lodash/isEqual";

export default class SpecCertificationEntity {
  public id!:number;
  public name:string|null = null;
  public authority:string|null = null;
  public target:string|null = null;
  public number:string|null = null;
  public note:string|null = null;

  public get isEmpty(): boolean {
    return !this.name && !this.authority && !this.target && !this.number && !this.note;
  }

  constructor(init:Partial<SpecCertificationEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export const ValidatorRules = {
  name: [createMaxStringRule()],
  authority: [createMaxStringRule()],
  target: [createMaxStringRule()],
  number: [createMaxStringRule()],
  note: [createMaxStringRule()],
};

