import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {CustomExportTemplateEntity, CustomExportTemplateResourceType} from "@/entities/custom-export-template-entity";

export class CompanyCustomExportTemplateRepository extends RepositoryBase<CustomExportTemplateEntity> {
  protected ctor:new(data) => CustomExportTemplateEntity = CustomExportTemplateEntity;

  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/custom-export-templates`
  }

  public async all(resourceType: CustomExportTemplateResourceType): Promise<CustomExportTemplateEntity[]>  {
    const res = await this.get(this.endpoint + `/${resourceType}`);
    return this.parseResponse(res) as CustomExportTemplateEntity[];
  }
  public async add(data: CustomExportTemplateEntity, resourceType: CustomExportTemplateResourceType) {
    return this.post(this.endpoint + `/${resourceType}`, data);
  }
  public async modify(data: CustomExportTemplateEntity): Promise<void>  {
    await super.put(this.endpoint + `/${data.id}`, data);
  }
  public async updateSortOrder(id:number, sortOrder: number): Promise<void>  {
    await super.put(this.endpoint + `/${id}/sort-order`, {sortOrder});
  }
  public async remove(id: number) {
    return this.destroy(id);
  }
}
