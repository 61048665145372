import ObjectUtils from "@/utils/object-utils";

export enum Permission {
  LabelImportRecipe = 'label_import_recipe',
  LabelCustomExportProduct = 'label_custom_export_product',
  LabelCustomExportOwnSpec = 'label_custom_export_own_spec',
}

export class CompanyPermissionEntity {
  public permission!: Permission;
  public quota:number | null = null;

  constructor(init:CompanyPermissionEntity) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}
