import ProductEntity from "@/entities/product-entity";

import sumBy from "lodash/sumBy";
import groupBy from "lodash/groupBy";
import maxBy from "lodash/maxBy";
import ProductDisplaySetting, {
  IAdditiveOption,
  IAllergenOption,
} from "@/entities/product-display-setting";
import ProductDisplayServiceAdditive, {
  IMergedNormalizedAdditiveItem
} from "@/services/product-display-service-additive";
import ProductDisplayServiceMadeInArea from "@/services/product-display-service-made-in-area";
import IngredientEntity from "@/entities/ingredient-entity";
import IngredientItemEntity from "@/entities/ingredient-item-entity";
import ProductDisplayServiceAllergen from "@/services/product-display-service-allergen";
import ProductDisplayServiceGMO from "@/services/product-display-service-gmo";
import CarryOver from "@/entities/carryover-entity";
import StringUtil from '@/utils/string-utils';
import IngredientItemGmoEntity from "@/entities/ingredient-item-gmo-entity";
import AllergyEntity from "@/entities/allergy-entity";
import {FoodType} from "@/entities/specs/spec-entity";
import uniqBy from "lodash/uniqBy";
import {CompanySettingEntity} from "@/entities/company-setting-entity";
import round from "lodash/round";
import {IRecipeComponent, IRecipeComponentItem} from "@/entities/interfaces/i-recipe-component";

export interface ISetItem {
  name?: string;
  items: INormalizedItem[];
}

export interface INormalizedItem {
  displayName: string;
  amountRatioInTheDirectParent: number;
  amountRatioInRootProduct: number;
  concentrationRatioInTotal: number;
  concentratedAmountRatioInTotal: number;
  rateForIngredientOrder: number;
  items: INormalizedItem[];
  item: IRecipeComponentItem|null;
  allergens: AllergyEntity[];
  allergensAll: AllergyEntity[];
  gmos: IngredientItemGmoEntity[];
  ingredient: IngredientEntity;
  hidden: boolean;
}

export interface IMergedNormalizedItem {
  displayName: string;
  consumptionRatio: number;
  concentrationAmountRatioInRootProduct: number;
  rateForIngredientOrder: number;
  amountRatioInTheDirectParent: number;
  items: IMergedNormalizedItem[];
  areas: string[];
  allergens: AllergyEntity[];
  allergensAll: AllergyEntity[];
  gmos: IngredientItemGmoEntity[];
  ingredients: IngredientEntity[];
  hidden: boolean;
  isPreproduct: boolean;
}

export type IngredientNameOption = {
  calcWithReducedWeight: boolean;
};

export function containsWater(item: INormalizedItem) : boolean {
  return item.displayName === '水' || item.items.some(containsWater);
}

export default class ProductDisplayService {
  private static readonly INGREDIENT_SEPARATOR = '、';

  public constructor(
    public readonly product: IRecipeComponent,
    public readonly setting: ProductDisplaySetting,
    public readonly companySetting: CompanySettingEntity,
    public readonly debug: boolean = false,
  ) {
  }

  public getIngredientNames() {
    if (!this.product.isProduct) throw new Error('invalid operation ProductDispalyService@getIngredientNames');
    const p = this.product as ProductEntity;
    if (p.isAssort) {
      return this.getIngredientNamesForAssort();
    } else {
      const items = p.isProductMix ?
        ProductDisplayService.normalizeForProductMix(p, this.setting) :
        ProductDisplayService.normalize(p, this.setting);
      return this.getIngredientNamesByItems(this.product, items, this.setting);
    }
  }
  // セット商品の原材料表示
  public getIngredientNamesForAssort() {
    return (this.product as ProductEntity).productItems
      .filter(pi => !!pi.childProduct)
      .map(pi => `【${pi.childProduct!.getDisplayName()}】` + this.getIngredientNamesSingle(pi.childProduct!))
      .join("\n");
  }
  // 通常商品の原材料表示
  public getIngredientNamesSingle(product: IRecipeComponent = this.product, setting:ProductDisplaySetting = this.setting) {
    const items = ProductDisplayService.normalize(product, setting);
    return this.getIngredientNamesByItems(product, items, setting);
  }

  // 中間原材料の原材料表示
  public static getIngredientNamesByItemsForIngredient(
    ingredient: IngredientEntity,
    companySetting: CompanySettingEntity
  ): string {
    const items = ProductDisplayService.normalize(ingredient, { calcWithReducedWeight: false });
    const opt = new ProductDisplaySetting({
      isAllergenSummarized: false,
      isRepeatedAllergenOmitted: false,
      isManyCompositeItemsOmitted: false,
      isFewCompositeItemsOmitted: false,
      isThickenerAdditiveGrouped: false,
      isSameFunctionAdditiveGrouped: false,
    });
    return new ProductDisplayService(ingredient, opt, companySetting).getIngredientNamesByItems(ingredient, items, opt);
  }

  public static normalizeForProductMix(data:ProductEntity, opt: IngredientNameOption): INormalizedItem[] {
    return data.productItems
      .filter(item => !!item.childProduct)
      .flatMap(pi => {
        return pi.childProduct!.items
          .filter(item => !!item.getChildIngredient())
          .flatMap(item => {
            const p = pi.childProduct!!;
            return ProductDisplayService.normalizeItemRecursive(
              item,
              opt,
              item.amountRatioInTheDirectParent * pi.amountRatioInTheDirectParent,
              p.concentrationRatio,
              p.getRateForIngredientOrder(opt.calcWithReducedWeight),
              1,
              p.getAllCarryoversRecursive()
            );
          });
      });
  }

  public static normalize(data:IRecipeComponent, opt: IngredientNameOption): INormalizedItem[] {
    return data.items
      .filter(item => !!item.getChildIngredient())
      .flatMap(item => {
        return ProductDisplayService.normalizeItemRecursive(
          item,
          opt,
          item.amountRatioInTheDirectParent,
          // 1 / data.concentrationRatio,
          // data.getRateForIngredientOrder(opt.calcWithReducedWeight),
          1,
          1,
          1,
          data.getAllCarryoversRecursive()
        );
      });
  }

  // 中間原材料、通常原材料（生鮮, 加工, 添加物）を、条件に応じて最小単位まで分割する
  private static normalizeItemRecursive(item: IRecipeComponentItem,
                                        opt: IngredientNameOption,
                                        amountRatioInRootProduct: number,
                                        concentrationRatio: number,
                                        rateForIngredientOrder: number,
                                        level: number,
                                        carryovers: CarryOver[]): INormalizedItem|INormalizedItem[] {

    const ingredient = item.getChildIngredient()!;
    if (!ingredient) {
      throw new Error(`原材料が設定されていないか、削除されています。[itemid=${item.id}][parentid=${item.parent.id}, product=${item.parent.isProduct}]`);
    }

    const carryoverIngredientItemIds = carryovers
      .filter(c => !!c.carryoverIngredientItemId)
      .map(c => c.carryoverIngredientItemId);
    const split = item.isCompositeSplitted || false;

    const defaultItem = {
      displayName: ingredient.displayName || '',
      amountRatioInTheDirectParent: item.amountRatioInTheDirectParent,
      amountRatioInRootProduct: amountRatioInRootProduct,
      concentrationRatioInTotal: concentrationRatio,
      concentratedAmountRatioInTotal: amountRatioInRootProduct * concentrationRatio,
      rateForIngredientOrder: rateForIngredientOrder,
      item: item,
      ingredient: ingredient,
      items: [],
      hidden: false,
      allergens: [],
      allergensAll: [],
      gmos: [],
    };

    // 中間原材料
    if (ingredient.isPreproduct) {
      const normalizePreproductItems = (ingredientItems: IngredientItemEntity[], level) => {
        return ingredientItems.flatMap(ii => ProductDisplayService.normalizeItemRecursive(
          ii,
          opt,
          amountRatioInRootProduct * ii.amountRatioInTheIngredient,
          concentrationRatio * ingredient.concentrationRatio,
          rateForIngredientOrder * ingredient.getRateForIngredientOrder(opt.calcWithReducedWeight),
          level,
          carryovers
        ) || []);
      };

      // 「2階層目以降の中間原材料は必ず分割する」
      if (split || level > 1) {
        return normalizePreproductItems(ingredient.ingredientItems, level);
      } else {
        // 分割しない中間原材料
        return Object.assign({}, defaultItem, {
          items: normalizePreproductItems(ingredient.ingredientItems, level + 1),
        });
      }
    }

    // 加工食品 or 添加物（製剤）
    if (ingredient.type === FoodType.Process || ingredient.type === FoodType.Additive) {
      // TODO: 将来的に、複合原材料の中身が他の原材料を参照することになるので、その時は対応する(child_ingredient_id)

      const normalizeCompositeItems = (ii: IngredientItemEntity, _amountRatioInTheDirectParent): INormalizedItem => {
        const itemAmountRatioInRootProduct = amountRatioInRootProduct * ii.amountRatioInTheIngredient;
        return Object.assign({}, defaultItem, {
          displayName: ii.name || '',
          item: ii,
          amountRatioInRootProduct: itemAmountRatioInRootProduct,
          amountRatioInTheDirectParent: _amountRatioInTheDirectParent,
          concentratedAmountRatioInTotal: itemAmountRatioInRootProduct * concentrationRatio,
          hidden: carryoverIngredientItemIds.includes(ii.id),
          allergens: ii.allergies.filter(a => a.id !== ii.allergenIdToOmitDisplay),
          allergensAll: ii.allergies,
          gmos: ii.ingredientItemGmos,
        });
      };

      if (ingredient.type === FoodType.Additive || split) {
        const emptyParent = Object.assign({}, defaultItem, { displayName: '',}) as INormalizedItem;

        // 分割表示
        return ingredient.ingredientItems.map(ii => {
          return normalizeCompositeItems(ii, amountRatioInRootProduct * ii.amountRatioInTheIngredient);
        }).concat([emptyParent]);
      } else {
        // 複合原材料表示
        const items = ingredient.ingredientItems.map(ii => normalizeCompositeItems(ii, ii.amountRatioInTheIngredient) );

        if (ingredient.ingredientDisplaySetting.isCompositeHidden) {
          // 「原材料表示名から内容が明らか」
          return Object.assign({}, defaultItem, {
            allergens: items.flatMap(i => i.ingredient.getAllAllergens()), // 子が省略されるので、アレルゲン情報を親に移す
            allergensAll: items.flatMap(i => i.ingredient.getAllAllergens()),
            gmos: items.flatMap(i => i.gmos),
            items: items.map(i => ({...i, displayName: ''})),
            // 同名の内訳があり、それが非表示なら非表示
            hidden: items.some(i => i.displayName === ingredient.displayName && i.hidden),
          });
        } else {
          return Object.assign({}, defaultItem, {items: items});
        }
      }
    }

    // 生鮮食品
    if (ingredient.isFresh) {
      const freshItem = ingredient.ingredientItems[0];
      return Object.assign({}, defaultItem, {
        item: freshItem,
        hidden: carryoverIngredientItemIds.includes(freshItem.id),
        allergens: freshItem.allergies.filter(a => a.id !== freshItem.allergenIdToOmitDisplay),
        allergensAll: freshItem.allergies,
        gmos: freshItem.ingredientItemGmos
      });
    }

    throw new Error('invalid operation ProductDispalyService@normalizeItemRecursive');
  }

  private getIngredientNamesByItems(product: IRecipeComponent, items:INormalizedItem[], setting: ProductDisplaySetting = this.setting): string {

    const sorted: IMergedNormalizedItem[] = this.mergeAndSortNormalizedItem(product, items);

    if(this.debug) {
      console.log('product.getTotalConcentrationRate()', product.getTotalConcentrationRate(false));
      console.log('normalized', items);
      console.log('sorted', sorted);
    }

    ProductDisplayServiceAllergen.init();
    const visibleItems = ProductDisplayService.excludeFromIngredientNamesRecursive(sorted, setting.isWaterOmitted);
    const maxAmount = visibleItems.length > 0 ? visibleItems[0].concentrationAmountRatioInRootProduct : 0;
    // GMOをうまく表示するため、とりあえずvisibleItemsではなくsortedを渡す（中でまたexcludeしてる)
    let names = this.createNamesFromItems(sorted, maxAmount);

    let result = names.join(ProductDisplayService.INGREDIENT_SEPARATOR);

    // 添加物表示
    const additive = ProductDisplayService.getAdditiveNames(product, setting, this.debug);
    if(additive) {
      result += ('／' + additive);
    }

    // アレルギー一括表示
    if (setting.isAllergenSummarized) {
      const uniqueAllergenNameList = this.getAllergenNamesByItems(items);
      const allergenSuffix =  uniqueAllergenNameList.length > 0 ? `、（一部に${uniqueAllergenNameList.join('・')}を含む）` : '';
      result += allergenSuffix;
    }

    if (setting.isKatakanaAsHankaku) {
      result = StringUtil.zenkakuKanaToHankaku(result);
    }

    return result;
  }

  public getAllergenNamesByItems(items: INormalizedItem[]): string[] {
    const allergens = items.flatMap(item => item.ingredient.getAllAllergens());
    return ProductDisplayServiceAllergen.sortAndFilterForDisplay(allergens).map(a => a.getDisplayName(false));
  }

  // 表示名毎でグルーピングして、重量割合順に並び替える
  public mergeAndSortNormalizedItem(product: IRecipeComponent, items:INormalizedItem[]): IMergedNormalizedItem[] {
    // 100％換算に割り直す
    const totalConcentrationRate = product.getTotalConcentrationRate(false);
    const hiddenRatio = this.companySetting.hiddenWhenAllSameNameIngredientInvisible ?
      1 - sumBy(items.filter(i => i.hidden), (i:INormalizedItem) => i.concentratedAmountRatioInTotal) :
      1;

    const mergeIngredients = (isPreproduct: boolean): IMergedNormalizedItem[] => {
      const filteredItems = items.filter(i => i.ingredient.isPreproduct === isPreproduct);
      const grouped = groupBy(filteredItems, (i: INormalizedItem) => i.displayName);

      return Object.values(grouped).map((g:INormalizedItem[]) => {
        const ingredients = g.flatMap(i => i.ingredient);
        // 非表示のものは配合量カウントしない
        const visibleItems = g.filter(i => !i.hidden);
        const hidden = this.companySetting.hiddenWhenAllSameNameIngredientInvisible
          ? g.every(i => i.hidden)
          : g.some(i => i.hidden);

        const rateForIngredientOrder = sumBy(visibleItems, (i:INormalizedItem) => i.amountRatioInRootProduct * i.rateForIngredientOrder);
        const consumptionRatio = sumBy(visibleItems, (i:INormalizedItem) => i.concentratedAmountRatioInTotal);

        return {
          displayName: g[0].displayName,
          consumptionRatio: consumptionRatio,
          concentrationAmountRatioInRootProduct: consumptionRatio / totalConcentrationRate / hiddenRatio,
          rateForIngredientOrder: rateForIngredientOrder,
          amountRatioInTheDirectParent: sumBy(visibleItems, (i:INormalizedItem) => i.amountRatioInTheDirectParent),
          items: g.flatMap(i => i.items).length ? this.mergeAndSortNormalizedItem(product, g.flatMap(i => i.items)) : [],
          ingredients: ingredients,
          hidden: hidden,
          allergens: g.flatMap(i => i.allergens).sort((a, b) => a.order - b.order),
          allergensAll: uniqBy(g.flatMap(i => i.allergensAll), a => a.id).sort((a, b) => a.order - b.order),
          gmos: uniqBy(g.flatMap(i => i.gmos), (gmo:IngredientItemGmoEntity) => gmo.id),
          isPreproduct: isPreproduct,
          areas: ProductDisplayServiceMadeInArea.getMadeInNameList(ingredients, this.setting)
        };
      });
    };

    const merged: IMergedNormalizedItem[]  = mergeIngredients(false).concat(mergeIngredients(true));

    return merged.sort((a, b) => {
      return (b.rateForIngredientOrder - a.rateForIngredientOrder)
        || (a.displayName.codePointAt(0)! - b.displayName.codePointAt(0)!);
    });
  }

  // 名前を取り出してテキストにする
  private createNamesFromItems(items:IMergedNormalizedItem[], maxAmount:number, setting:ProductDisplaySetting = this.setting): string[] {
    const opt = setting;

    // 複合原材料が原材料全体の5％未満の場合、 複合原材料の後ろの（）書きを省略できます
    const getInnerItemsWithoutOmit = (item:IMergedNormalizedItem):IMergedNormalizedItem[] => {
      // 中間原材料は省略しない
      if (item.isPreproduct) return item.items;

      const omitComposite = item.concentrationAmountRatioInRootProduct < 0.05 && opt.isFewCompositeItemsOmitted;
      return omitComposite ? [] : item.items;
    };

    // 一番最初の原材料にエリアを付与

    // 複合原材料において3種類以上の原材料があり、3位以下で(その原材料内において)5％未満のものは「その他」と表示が可能です。
    const roundInnerItems = (items:IMergedNormalizedItem[]):IMergedNormalizedItem[] => {
      if (!opt.isManyCompositeItemsOmitted) return items;

      const innerItems = items.filter(s => s.amountRatioInTheDirectParent >= 0.05 || items.indexOf(s) < 2);
      if (innerItems.length !== items.length) {
        innerItems.push({
          displayName: 'その他',
          // 以下dummy
          consumptionRatio: 0, concentrationAmountRatioInRootProduct: 0, rateForIngredientOrder: 0, amountRatioInTheDirectParent: 0,
          ingredients: [], items: [], hidden: false, gmos: [], allergens: [], allergensAll: [], isPreproduct: false, areas: [],
        });
      }
      return innerItems;
    };

    // GMOをうまく表示するため
    const visibleItems = ProductDisplayService.excludeFromIngredientNamesRecursive(items, opt.isWaterOmitted);
    return visibleItems.map((item:IMergedNormalizedItem) => {
      let innerItems:IMergedNormalizedItem[] = getInnerItemsWithoutOmit(item);

      const allergen = ProductDisplayServiceAllergen.getAllergenSuffix(item.allergens, opt.isAllergenSummarized, opt.isRepeatedAllergenOmitted);

      const dummyAmountToInvisibleAreaOnInnerItem = 999;

      const devSuffix = this.debug ?
        `<濃縮率:${round(item.concentrationAmountRatioInRootProduct, 3)}:表示順割合${round(item.rateForIngredientOrder, 3)}>` :
        '';
      if (item.isPreproduct) {
        const childMaxAmount = (item.concentrationAmountRatioInRootProduct === maxAmount && item.items.length > 0) ?
          item.items[0].concentrationAmountRatioInRootProduct :
          dummyAmountToInvisibleAreaOnInnerItem;

        const inner = innerItems.length > 0
          ? '（' + this.createNamesFromItems(innerItems, childMaxAmount).join(ProductDisplayService.INGREDIENT_SEPARATOR) + '）'
          : '';

        return item.displayName + inner + allergen + devSuffix;
      } else {
        // 一番量が多い原材料にのみエリアを付与
        const area = (!opt.isMadeInAreasSeparated && item.concentrationAmountRatioInRootProduct === maxAmount && item.areas.length > 0) ?
          "（" + ProductDisplayServiceMadeInArea.joinAreas(item.areas, opt) + "）" :
          '';

        if (innerItems.length) {
          // 複合原材料
          innerItems = roundInnerItems(innerItems);

          const inner = '（' + this.createNamesFromItems(innerItems, dummyAmountToInvisibleAreaOnInnerItem).join(ProductDisplayService.INGREDIENT_SEPARATOR) + '）';
          return item.displayName + inner + allergen + area + devSuffix;
        } else {
          const gmo = ProductDisplayServiceGMO.getGMOLabels(item, items, this.companySetting, this.setting);
          return item.displayName + allergen + area + gmo + devSuffix;
        }
      }

    }).filter(s => s !== null) as string[];
  }

  public static getMergedAdditives(
    model:IRecipeComponent,
    setting: IAllergenOption&IAdditiveOption&IngredientNameOption,
    dev: boolean = false
  ): IMergedNormalizedAdditiveItem[] {
    const additives = ProductDisplayServiceAdditive.flattenAdditiveList(model, setting);
    const carryovers = model.getAllCarryoversRecursive();
    const carryoverIngredientAdditiveIds = carryovers
      .filter(c => !!c.carryoverIngredientAdditiveId)
      .map(c => c.carryoverIngredientAdditiveId!);
    return ProductDisplayServiceAdditive.getMergedAdditiveItems(additives, carryoverIngredientAdditiveIds, setting, dev);
  }
  public static getAdditiveNames(model:IRecipeComponent, setting: IAllergenOption&IAdditiveOption&IngredientNameOption, dev: boolean = false): string {
    const sorted = ProductDisplayService.getMergedAdditives(model, setting, dev);
    return ProductDisplayServiceAdditive.getAdditiveNames(sorted, setting, dev);
  }

  // 一括表記から削除するものを除外
  public static excludeFromIngredientNamesRecursive<T extends INormalizedItem|IMergedNormalizedItem>(
    items:T[]
    , isWaterOmitted: boolean
    , excludeHidden: boolean = true
  ): T[]
  {
    let filtered = items.filter(i => !!i.displayName);
    if (excludeHidden) {
      filtered = filtered.filter(i => !i.hidden);
    }
    if (isWaterOmitted) {
      filtered = filtered.filter(i => i.displayName !== '水');
    }
    return filtered.map((i:T) => {
      i.items = ProductDisplayService.excludeFromIngredientNamesRecursive(i.items as T[], isWaterOmitted, excludeHidden) as INormalizedItem[]|IMergedNormalizedItem[];
      return i;
    })
  }

  // region Delegations
  public getMadeInNames(appendOrPrependIngredientName:'append'|'prepend'|false = false, setting:ProductDisplaySetting = this.setting) {
    return (new ProductDisplayServiceMadeInArea(this.product, setting, this.companySetting))
      .getMadeInNames(appendOrPrependIngredientName);
  }
  // endregion Delegations
}

