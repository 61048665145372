


































import {Component, Prop} from 'vue-property-decorator';
import {ValidateWithScroll} from "@/utils/validation-handler";
import Vue from "vue";
import {
  CustomExportTemplateEntity,
  CustomExportTemplateOutputExtension, CustomExportTemplateOutputExtensionDict
} from "@/entities/custom-export-template-entity";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";
import {Mimes} from "@/components/Form/Thumbnail/input-thumbnail";

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: false, default: () => {} }) private rules!: object;
  @Prop({required: true}) private value!: boolean;
  @Prop({required: true}) private helpLink!: string;

  private readonly Mimes = Mimes;
  private readonly CustomExportTemplateOutputExtensionDict = CustomExportTemplateOutputExtensionDict;

  private get visible() { return this.value; }
  private set visible(val) { this.$emit('input', val) ; }

  private data = {
    id: null as number | null,
    name: '',
    template: IFileDefaultValue,
    outputExt: CustomExportTemplateOutputExtension.Excel,
  };

  private get title() {
    const action = this.$t('追加');
    return this.$t('common.of', { item: "「オリジナルフォーマット」", action: action });
  }
  private get buttonLabel() {
    return this.$t('以上で作成');
  }

  private onFileSelected(file: IFile) {
    if (!this.data.name) {
      this.data.name = file.filename;
    }
  }

  private async save() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    this.$emit('save', this.data);
  }
}
