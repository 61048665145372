import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import {LabelPrintSettingEntity } from "@/entities/label-print-setting-entity";

export class LabelPrintSettingRepository extends RepositoryBase<LabelPrintSettingEntity> {
  public constructor(companyId:string|number) {
    super();
    this.endpoint = `companies/${companyId}/label-print-settings`
  }

  public async all(): Promise<LabelPrintSettingEntity[]>  {
    const res = await this.get(this.endpoint);
    return res.data.map(d => new LabelPrintSettingEntity(d));
  }
  public async find(id:number): Promise<LabelPrintSettingEntity>  {
    const res = await this.get(`${this.endpoint}/${id}`);
    return new LabelPrintSettingEntity(res.data);
  }
  public async updateName(id:number, name:string): Promise<LabelPrintSettingEntity>  {
    const res = await this.update(id, { name: name });
    return new LabelPrintSettingEntity(res.data);
  }
  public async upsert(data:LabelPrintSettingEntity[]): Promise<LabelPrintSettingEntity[]> {
    const res = await this.post(this.endpoint + '/upsert', { data });
    return res.data.map(d => new LabelPrintSettingEntity(d));
  }

}
