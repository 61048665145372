


















































































































































































































































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {FoodTypeForSpec, SpecCreateBaseEntity, ValidatorRules} from "@/entities/specs/spec-entity";

import SpecIngredientEntity, {
  SpecIngredientAllergenEntity,
  SpecIngredientGmoEntity,
  SpecIngredientOriginEntity,
  SpecIngredientType,
} from "@/entities/specs/spec-ingredient-entity";
import AdditiveRepository, {
  createAdditiveFormulationList,
  TAdditiveSynonymListItem
} from "@/repositories/master/additive-repository";
import {CarryoverReasonDict} from '@/entities/carryover-entity';
import MadeInAreaRepository from "@/repositories/master/made-in-area-repository";
import {MadeInAreaEntity} from "@/entities/ingredient-made-in-area-entity";
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import GmoCropSelect from "@/views/label/companies/ingredient/components/ingredient-item-table/GmoCropSelect.vue";
import GmoTypeSelect from "@/views/label/companies/ingredient/components/ingredient-item-table/GmoTypeSelect.vue";
import sum from 'lodash/sum';
import round from 'lodash/round';
import Decimal from 'decimal.js';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import {SpecDocumentAttachmentPreparedType} from "@/entities/specs/spec-attachment-entity";

import SmashokuLabelPanel from './components/SmashokuLabelPanel.vue';
import {TreeUtils} from '@/utils/tree-utils';
import Loading from "@/utils/loading-handler";

import {help as PopoverText} from '@/lang/help/spec-create';
import SpecRepository from "@/repositories/spec/company/spec-repository";
import InputAttachment from "@/views/spec/companies/components/spec-components/create/components/InputAttachment.vue";
import CustomValueCreateSection
  from "@/views/spec/companies/components/spec-components/create/components/CustomValueCreateSection.vue";
import {SpecCustomValueCategory} from '@/entities/specs/spec-custom-value-entity';
import AdditiveSelection from "@/components/Project/Additive/AdditiveSelection.vue";
import AllergenSelectionMultiple from "@/components/Project/Allergen/AllergenSelectionMultiple.vue";
import AllergensColumn from "@/views/label/companies/ingredient/components/ingredient-item-table/AllergensColumn.vue";
import SpecImExportError, {
  SpecImExportErrorIngredientAttribute,
  SpecImportErrorIngredientPropType
} from "@/entities/specs/spec-im-export-error";
import AdditivePurposeSelection from "@/components/Project/Additive/AdditivePurposeSelection.vue";
import uniq from "lodash/uniq";
import SpecIngredientCollection from '@/entities/specs/spec-ingredient-collection';
import RowKey from "@/entities/concerns/rowkey";
import {Table as VXETable} from "vxe-table/types/table";
import SpecIngredientGmoModal
  from "@/views/spec/companies/components/spec-components/create/components/ingredients/SpecIngredientGmoModal.vue";
import SpecIngredientOriginMaterialModal
  from "@/views/spec/companies/components/spec-components/create/components/ingredients/SpecIngredientOriginMaterialModal.vue";
import MadeInAreaSelection from "@/components/Project/MadeInArea/MadeInAreaSelection.vue";
import CompanyEntity from "@/entities/company-entity";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";
import BooleanSelectVirtual from "@/components/Form/BooleanSelectVirtual.vue";
import StringUtils from "@/utils/string-utils";
import TooltipWarning from "@/components/Tooltip/TooltipWarning.vue";
import {AdditiveType} from "@/entities/additive-entity";
import SpecIngredientAllergenModal
  from "@/views/spec/companies/components/spec-components/create/components/ingredients/SpecIngredientAllergenModal.vue";

@Component({
  components: {
    SpecIngredientAllergenModal,
    TooltipWarning,
    BooleanSelectVirtual,
    SpecIngredientGmoModal,
    MadeInAreaSelection,
    SpecIngredientOriginMaterialModal,
    AdditivePurposeSelection,
    AllergensColumn,
    AllergenSelectionMultiple,
    AdditiveSelection,
    SmashokuLabelPanel,
    AddDeleteTable,
    GmoCropSelect,
    GmoTypeSelect,
    InputAttachment,
    CustomValueCreateSection,
  },
})
export default class Ingredient extends Vue {
  @Prop({required: true}) private model!:SpecCreateBaseEntity;
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: false, default: false}) private isSelfSubmission!:boolean;
  @Prop({required: false, default: () => ValidatorRules}) private rules!:typeof ValidatorRules;
  @Prop({required: false}) private customValueSettings?:CompanySpecCustomValueSettingEntity[];

  @Prop({required: false}) private convertingError?:SpecImExportError;

  private readonly PopoverText = PopoverText;
  private readonly FoodTypeForSpec = FoodTypeForSpec;

  private readonly SpecIngredientType = SpecIngredientType;
  private readonly CarryoverReasonDict = CarryoverReasonDict;
  private readonly SpecDocumentAttachmentPreparedType = SpecDocumentAttachmentPreparedType;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
  private additiveSynonymList:TAdditiveSynonymListItem[] = [];
  private additiveSynonymListFormulation:TAdditiveSynonymListItem[] = [];
  private madeInAreas: MadeInAreaEntity[] = [];

  private readonly SpecImportErrorIngredientPropType = SpecImportErrorIngredientPropType;
  private readonly uniq = uniq;

  private initialized = false;

  private readonly treeUtil = new TreeUtils<SpecIngredientEntity>(this.model.ingredientsNested, this.model.ingredients)

  private isTableWide = true;

  private get isDev():boolean {
    return !!this.$route.query.dev;
  }

  private row(scope: {row: SpecIngredientEntity}) {
    return scope.row;
  }

  private created() {
    if (!this.model.ingredients.length) {
      this.addRow();
    }
    this.model.ingredients.forEach(i => this.initRow(i));

    Promise.all([
      new AdditiveRepository().list().then(list => {
        this.additiveSynonymList = list;
        this.additiveSynonymListFormulation = createAdditiveFormulationList(list);
      }),
      (new MadeInAreaRepository).findAllWithCache().then((list:MadeInAreaEntity[]) => {
        this.madeInAreas = list;
      }),
    ]).then(() => {
      this.initialized = true;
      this.$emit('initialized');
    });
  }
  private mounted() {
    this.waitUntilInitialized().then(() => {
      this.setImportErrorBG();
      this.model.ingredients.forEach(i => this.setEventToRow(i));
    });
  }
  private waitUntilInitialized(interval: number = 100) {
    return new Promise<void>((resolve, reject) => {
      const func = () => {
        if (this.initialized) {
          this.$nextTick(resolve);
        } else {
          setTimeout(func, interval);
        }
      };
      func();
    });
  }
  private setAndGetNameAsIngredient(): string | null {
    this.model.nameAsIngredient = this.model.getNameAsIngredient(this.additiveSynonymListFormulation);
    return this.model.nameAsIngredient;
  }

  private setImportErrorBG() {
    if (!this.convertingError) return;

    const css = this.convertingError!.ingredientErrors.map(i => {
      const ing = this.model.ingredients[i.index - 1] || null;
      if (ing === null) return '';
      return `.vxe-body--row[rowid='${ing.rowKey}']`;
    }).join(",");
    const style = document.createElement('style');
    style.innerText = css + '{ background-color: var(--color-bg-primary); }';
    document.head.appendChild(style);
  }

  private initRow(row:SpecIngredientEntity) {
    if (row.origins.length === 0) {
      row.origins.push(new SpecIngredientOriginEntity());
    }
    if (row.allergens.length === 0) {
      row.allergens.push(new SpecIngredientAllergenEntity());
    }
    if (row.gmos.length === 0) {
      row.gmos.push(new SpecIngredientGmoEntity());
    }
  }

  private async addRow() {
    const row = new SpecIngredientEntity();
    row.type = this.treeUtil.lastRootChild ? this.treeUtil.lastRootChild.type : row.type;
    this.initRow(row);
    this.treeUtil.add(row);
    this.scrollToRow(row);
    await this.$nextTick(() => {
      this.setEventToRow(row);
    });
    return row;
  }
  private async insertRow(bro:SpecIngredientEntity) {
    const insertingRow = new SpecIngredientEntity({type: bro.type});
    this.initRow(insertingRow);
    this.treeUtil.insert(bro, insertingRow);
    await this.rerenderTree();
    return insertingRow;
  }
  private async cloneRow(org:SpecIngredientEntity) {
    const cloned = RowKey.clone(org, 'children');
    this.treeUtil.insert(org, cloned);
    await this.rerenderTree();
    return cloned;
  }
  private deleteRow(ing:SpecIngredientEntity) {
    this.treeUtil.delete(ing);
  }
  // region indent
  private canIndentUp(ing:SpecIngredientEntity):boolean {
    return !this.treeUtil.isFirstChild(ing);
  }
  private canIndentDown(ing:SpecIngredientEntity):boolean {
    return !this.treeUtil.isLastChild(ing);
  }
  private canIndentLeft(ing:SpecIngredientEntity):boolean {
    return !!this.treeUtil.getGrandParent(ing);
  }
  private canIndentRight(ing:SpecIngredientEntity):boolean {
    if (this.treeUtil.isFirstChild(ing)) return false;

    const bros = this.treeUtil.getBrothers(ing);
    const closestBigBrother = bros[bros.indexOf(ing) - 1];
    if (closestBigBrother.type === SpecIngredientType.Additive) {
      // 添加物の下には原材料のみおけるようにする
      return ing.type === SpecIngredientType.Ingredient;
    }

    return true;
  }
  private async indentUp(ing:SpecIngredientEntity) {
    const bros = this.treeUtil.getBrothers(ing);
    const closestBigBrotherPosition = bros.indexOf(ing) - 1;
    bros.splice(closestBigBrotherPosition, 2, ing, bros[closestBigBrotherPosition]);
    await this.rerenderTree();
  }
  private async indentDown(ing:SpecIngredientEntity) {
    const bros = this.treeUtil.getBrothers(ing);
    bros.splice(bros.indexOf(ing), 2, bros[bros.indexOf(ing) + 1], ing);
    await this.rerenderTree();
  }
  private async indentLeft(ing:SpecIngredientEntity) {
    const parent = this.treeUtil.findParent(ing);
    const grandParent = this.treeUtil.findParent(parent);

    const parentBros = this.treeUtil.getBrothers(parent);
    const parentPosition = parentBros.indexOf(parent);
    this.treeUtil.switchParent(ing, grandParent, parentPosition + 1);
    await this.rerenderTree();
  }
  private async indentRight(ing:SpecIngredientEntity) {
    const bros = this.treeUtil.getBrothers(ing);
    const closestBigBrother = bros[bros.indexOf(ing) - 1];

    if (
      closestBigBrother.children.length === 0 &&
      (
        closestBigBrother.amount ||
        closestBigBrother.nonBlankSpecIngredientAllergens.length > 0 ||
        closestBigBrother.gmos.filter(g => !g.isEmpty).length > 0 ||
        closestBigBrother.origins.filter(o => !o.isEmpty).length > 0
      )
    ) {
      try {
        await this.$confirm(
          `「${this.getMaterialName(closestBigBrother)}」${this.$t('に子が追加されると、その行の配合量・基原原料・アレルギー物質・遺伝子組み換え対象農作物の設定が無効になります')}`,
          this.$t('メンバー削除'),
          {
            confirmButtonText: this.$t('はい'),
            cancelButtonText: this.$t('キャンセル'),
          }
        );
      } catch(err) {
        if (err !== 'cancel' && err !== 'close') throw err;
        return;
      }
    }

    this.treeUtil.switchParent(ing, closestBigBrother, closestBigBrother.children.length);
    await this.rerenderTree();
  }
  // endregion indent

  private canSelectType(type: SpecIngredientType, ing: SpecIngredientEntity): boolean {

    // 親が添加物なら原材料のみ受付可
    const parent = this.treeUtil.findParent(ing);
    if (parent.isAdditive) {
      return type === SpecIngredientType.Ingredient;
    }
    // 子が原材料のみの場合だけ添加物にできる
    if (type == SpecIngredientType.Additive) {
      return ing.children.length === 0 || ing.children.every(c => c.type === SpecIngredientType.Ingredient)
    }

    return true;
  }

  private getMaterialName(row:SpecIngredientEntity) {
    return row.getMaterialName(this.additiveSynonymList);
  }

  private autoCompleteForNote(q, cb) {
    return cb([
      {value: this.$t('水')},
      {value: this.$t('加工助剤')},
      {value: this.$t('キャリーオーバー')}
    ]);
  }

  private async onIngredientNameChanged(ia:SpecIngredientEntity) {
    if (ia.$isDisplayNameChanged) return;
    ia.displayName = ia.ingredientName!;
  }
  private async onDisplayNameChanged(ia:SpecIngredientEntity) {
    ia.$isDisplayNameChanged = !!ia.displayName;
  }
  private async onSelectedAdditiveChanged(selected: TAdditiveSynonymListItem, ia:SpecIngredientEntity) {
    ia.additive = await (new AdditiveRepository()).findById(selected.additiveId);
    ia.additiveSynonym = ia.additiveSynonymId ? ia.additive.additiveSynonyms.find(as => as.id === ia.additiveSynonymId)! : null;
    ia.additivePurposeSynonymId = ia.additive.getDefaultAdditivePurposeSynonymId();
  }

  private async rerenderTree() {
    await this.waitUntilInitialized();
    await (this.$refs.xTree as VXETable).syncData();
    await this.expandAll();
    this.$nextTick(() => {
      this.model.ingredients.map(i => this.setEventToRow(i));
    });
  }
  private async expandAll() {
    if (!this.$refs.xTree) return;
    await this.$nextTick(() => {
      (this.$refs.xTree as VXETable).setAllTreeExpand(true);
    });
  }
  private importIngredientsFromProduct(productId:number) {
    Loading(async () => {
      const nestedIngredients:SpecIngredientEntity[] =
        await (new SpecRepository(this.company.id)).getIngredientsFromProduct(productId, this.model.isAmountRatioInTotal);
      const flatten = TreeUtils.flatten(nestedIngredients);

      // とりあえず。この値に合わせたimportは後で実装する
      if (!this.model.isAmountRatioInTotal) {
        // this.convertRatioFromTotalToDirectParent(this.model.ingredientsNested);
      }

      flatten.forEach((row:SpecIngredientEntity) => {
        this.initRow(row);
      });

      this.treeUtil.init(nestedIngredients, flatten);
      await this.rerenderTree();
    }, 180000);
  }
  private convertRatioFromTotalToDirectParent(ings: SpecIngredientEntity[], parentAmount: number = 100) {
    ings.forEach((ing: SpecIngredientEntity) => {
      if (ing.hasAnyChildren) {
        const sum = SpecIngredientCollection.getAmountSum(ing);
        ing.amount = (sum / parentAmount) * 100;
        this.convertRatioFromTotalToDirectParent(ing.children, ing.amount || 100);
      } else {
        if (ing.amount) {
          const dec = new Decimal(ing.amount);
          ing.amount = round(dec.div(parentAmount).times(100).toNumber(), 4);
        }
      }
    });
  }

  private async scrollToRow(row: SpecIngredientEntity) {
    const $tree = this.$refs.xTree as any as VXETable;
    if (!$tree) return;

    this.$nextTick(async () => {
      setTimeout(() => {
        $tree.scrollToRow(row);
      }, 50);
    });
  }
  private getRowIndexByRowKey(rowKey:number): number {
    return this.model.ingredients.findIndex(i => i.rowKey === rowKey);
  }

  private getConvertingErrorAttr(rowKey:number, prop:string): SpecImExportErrorIngredientAttribute | null {
    if (!this.convertingError) return null;
    const idx = this.getRowIndexByRowKey(rowKey);
    const ing = this.convertingError.ingredientErrors.find(i => i.index === idx + 1);
    if (!ing) return null;
    const attr = ing.attributes.find(a => a.prop === prop);
    if (!attr) return null;
    return attr;
  }
  private getConvertingError(rowKey:number, prop:string): string {
    const attr = this.getConvertingErrorAttr(rowKey, prop);
    if (!attr) return '';
    return attr.typeMessage;
  }
  private getConvertingErrorIconClass(rowKey:number, prop:string): string {
    const attr = this.getConvertingErrorAttr(rowKey, prop);
    if (!attr) return '';
    return attr.isTypeDanger ? 'el-icon-warning danger' : 'el-icon-warning';
  }

  private ruleContainsRequired(rule):boolean {
    if(!rule) return false;
    if (isArray(rule)) return rule.some(r => !!r.required);
    if (isObject(rule)) return !!(rule as any).required;
    return false;
  }

  private getChildDepth(ing:SpecIngredientEntity) {
    const collection = new SpecIngredientCollection(this.model, this.model.ingredients);
    return collection.getChildDepth(ing);
  }
  private getChildLevel(ing:SpecIngredientEntity): string {
    const collection = new SpecIngredientCollection(this.model, this.model.ingredientsNested);
    return collection.getLevel(ing);
  }

  private indexToLevel(index:number): string {
    // indexは1始まり
    if(this.model.ingredients.length < index) {
      throw new Error('indexToLevelがindex out of range');
    }
    return this.getChildLevel(this.model.ingredients[index - 1]);
  }

  private shouldShowVisibility(row:SpecIngredientEntity) {
    return row.type !== SpecIngredientType.AdditiveFormulation;
  }
  private shouldShowHiddenReason(row:SpecIngredientEntity) {
    return !row.visible && row.type !== SpecIngredientType.AdditiveFormulation;
  }
  private shouldShowLabelName(row:SpecIngredientEntity) {
    return row.visible && row.type !== SpecIngredientType.AdditiveFormulation;
  }

  private currentFocusingRow: SpecIngredientEntity | null = null;

  private getRowElement(fixed: boolean, targetIng:SpecIngredientEntity): HTMLElement | null {
    const $tableRef = this.$refs.xTree;
    if (!$tableRef) return null;
    const $table = (this.$refs.xTree as Vue).$el;
    const wrapperClass = fixed ? '.vxe-table--fixed-wrapper' : '.vxe-table--main-wrapper';
    const $rows = $table.querySelectorAll(`${wrapperClass} .vxe-body--row`);
    return Array.from($rows).find($row => Number($row.attributes['rowid'].value) === targetIng.rowKey) as HTMLElement;
  }
  private setEventToRow(ing: SpecIngredientEntity) {
    if (!this.$refs.xTree) return null;

    const $targetMain = this.getRowElement(false, ing)!;
    const $targetFixed = this.getRowElement(true, ing);

    const set = ($row: HTMLElement, fixed: boolean) => {
      $row.querySelectorAll('input').forEach(($input, idx) => {
        $input.addEventListener('focus', () => {
          this.currentFocusingRow = ing;
        });
        $input.addEventListener('blur', () => {
          this.currentFocusingRow = null;
        });
        $input.addEventListener('keydown', async (ev:KeyboardEvent) => {

          const onIndent = (targetIng: SpecIngredientEntity) => {
            ev.preventDefault();
            ev.stopPropagation();
            this.$nextTick().then(() => {
              this.getRowElement(fixed, targetIng)!.querySelectorAll('input')[idx].focus();
            });
          };

          if ((ev.ctrlKey || ev.metaKey) && ['Enter', 'NumpadEnter'].includes(ev.key)) {
            const newRow = await this.addRow();
            onIndent(newRow);
            return;
          }

          if (ev.altKey) {
            if (this.canIndentLeft(ing) && ['Left', 'ArrowLeft'].includes(ev.key)) {
              await this.indentLeft(ing);
              onIndent(ing);
            } else if(this.canIndentRight(ing) && ['Right', 'ArrowRight'].includes(ev.key)) {
              await this.indentRight(ing);
              onIndent(ing);
            } else if(this.canIndentUp(ing) && ['Up', 'ArrowUp'].includes(ev.key)) {
              await this.indentUp(ing);
              onIndent(ing);
            } else if(this.canIndentDown(ing) && ['Down', 'ArrowDown'].includes(ev.key)) {
              await this.indentDown(ing);
              onIndent(ing);
            } else if(['Enter', 'NumpadEnter'].includes(ev.key)) {
              const inserted = await this.insertRow(ing);
              onIndent(inserted);
            } else if(['ç', 'c'].includes(ev.key)) {
              const inserted = await this.cloneRow(ing);
              onIndent(inserted);
            }
          }
        });
      });
    };
    set($targetMain, false);
    if ($targetFixed) {
      set($targetFixed, true);
    }
  }
  private get currentFocusingLevelSumLabel(): string {
    if (!this.currentFocusingRow) return '';

    const parent = this.treeUtil.findParent(this.currentFocusingRow);
    if (parent === this.treeUtil.root)  return '';

    const brotherSum = round(sum(parent.children.map(c => this.model.getAmount(c))), 6);
    const parentLevel = this.getChildLevel(parent);
    const parentName = this.getMaterialName(parent);
    const name = parentName ? `「${parentName}」` : `階層番号「${parentLevel}」`;
    return `（${name}内の合計配合率：${brotherSum}％）`;
  }
  private getRowStyle(opt: {rowid: string, $rowIndex: number, seq: string, level: number}){
    if (!this.currentFocusingRow) return {};
    const bros = this.treeUtil.getBrothers(this.currentFocusingRow);
    const brosRowKeys = bros.map(b => b.rowKey.toString());
    if (!brosRowKeys.includes(opt.rowid)) return {};

    return {
      backgroundColor: '#FFFAF2',
    };
  }
  private onIsAmountRatioInTotalChanged() {
    this.rerenderTree();
  }
  private onKeyDownIngredientName(ev:KeyboardEvent, row:SpecIngredientEntity) {
    if (ev.key === 'Tab') {
      this.getRowElement(false, row)!.querySelectorAll('input')[0].focus();
      ev.preventDefault();
    }
  }

  private onAllergenChanged(row: SpecIngredientEntity) {
    if (row.nonBlankSpecIngredientAllergens.length > 0) {
      row.hasAllergen = true;
    }
  }
  private onGmoChanged(row: SpecIngredientEntity) {
    if (row.gmos.filter(g => !g.isEmpty).length > 0) {
      row.hasGmo = true;
    }
  }
  private onFormItemHover(event: MouseEvent, enter: boolean) {
    if (enter) {
      const $target = (event.target as HTMLElement);
      const $err = $target.querySelector('.js-el-form-item__error');
      if (!$err || !$err.textContent) return;
      const $tt = this.$refs.tooltip as HTMLElement;
      $tt.innerHTML = $err.textContent;
      $tt.style.left = (event.clientX - 80) + 'px';
      $tt.style.top = ($target.getBoundingClientRect().top + 36) + 'px';
      $tt.style.display = 'block';
    } else {
      const $tt = this.$refs.tooltip as HTMLElement;
      $tt.style.display = 'none';
    }
  }

  private isSimilarWithAdditiveName(ingredientItemName:string | null) {
    if (!ingredientItemName) return false;
    const needle = StringUtils.toHankakuKanaLower(ingredientItemName);
    return this.additiveSynonymList
      .filter(s => s.additiveType !== AdditiveType.GeneralFood)
      .some(s => s.synonym === needle);
  }

}
