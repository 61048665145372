import RepositoryBase, {IFindOption} from "@/repositories/repository-base";
import ObjectUtils from "@/utils/object-utils";
import {IPaginator} from "@/contracts/i-paginator";
import SpecEntity, {SpecCreateBaseEntity} from "@/entities/specs/spec-entity";
import SpecIngredientEntity from "@/entities/specs/spec-ingredient-entity";
import {AxiosResponse} from "axios";
import AuditEntity from "@/entities/audit-entity";
import {IFileUpload} from "@/contracts/i-file";
import {SpecMetaEntity} from "@/repositories/spec/company/spec-meta-repository";
import SpecImExportError from "@/entities/specs/spec-im-export-error";
import {CustomExportTemplateOutputExtension} from "@/entities/custom-export-template-entity";

export default class SpecRepository extends RepositoryBase<SpecEntity> {
  protected ctor:new(data) => SpecEntity = SpecEntity;
  private companyId!:number;

  public constructor(companyId:number) {
    super();
    this.endpoint = `spec/companies/${companyId}/specs`
    this.companyId = companyId;
  }

  public async search(query, params): Promise<IPaginator<SpecForIndex>>  {
    const p = Object.assign({_as_pagination: true, q:query}, params);
    const res = await this.get(this.endpoint + "/search", p);
    return this.toPagination(res, SpecForIndex);
  }

  public async find(specId:number): Promise<SpecEntity> {
    return await this.findById(specId);
  }
  public async findMeta(specId:number): Promise<SpecMetaEntity> {
    const res = await this.get(this.endpoint + `/${specId}/meta`);
    return new SpecMetaEntity(res.data);
  }

  public async listLatestSpecs(): Promise<SpecEntity[]>  {
    const res = await this.get(this.endpoint + '/list-latest-specs');
    return res.data;
  }

  public async listLatestSpecsForSubmission(): Promise<LatestSpecsForSubmission[]>  {
    const res = await this.get(this.endpoint + '/list-latest-specs-for-submission');
    return res.data.map(d => ({...d, search: d.name + d.nameKana + d.intraCode}));
  }

  public async getIngredientsFromProduct(productId: number, isAmountRatioTotal: boolean): Promise<SpecIngredientEntity[]> {
    const url = `spec/companies/${this.companyId}/spec-ingredients/from-product/${productId}?is_amount_ratio_total=${isAmountRatioTotal ? 1 : 0}`;
    const res = await this.get(url);
    return res.data.map(d => new SpecIngredientEntity(d));
  }

  public async validateExportSpec(specId:number, req:ExportEventArgs): Promise<ExportValidationResponse> {
    const res = await super.get(`${this.endpoint}/${specId}/report/${req.type}/${req.id}/validate`);
    return {
      success: res.data.success,
      error: res.data.error ? new SpecImExportError(res.data.error) : undefined,
    }
  }
  public async exportSpec(specId:number, req:IExportRequest): Promise<AxiosResponse> {
    return super.get(`${this.endpoint}/${specId}/report/${req.type}/${req.id}`, {
      format: req.outputFormat,
      arg1: req.arg1,
      pitsOption: req.pitsOption
    }, {
      responseType: 'blob'
    });
  }

  public async store(data) {
    return await this.post(this.endpoint, data);
  }
  public async revise(specId:number, data) {
    return await this.put(this.endpoint + `/${specId}`, data);
  }

  public async publish(specId:number) {
    await super.put(`${this.endpoint}/${specId}/publish`);
  }
  public async createByImporting(type:ImportDataType, file:IFileUpload|IFileUpload[], params: object = {}): Promise<ImportResponse> {
    const res = await super.post(`${this.endpoint}/import/prepared/${type}`, {file: file, params: params });
    return createImportResponse(res);
  }

  public async updateByImporting(specId:number, type:ImportDataType, file:IFileUpload|IFileUpload[], params: object = {}): Promise<ImportResponse>  {
    const res = await super.put(`${this.endpoint}/${specId}/import/prepared/${type}`, {file: file, params: params});
    return createImportResponse(res);
  }

  public async getAuditLogs(id:number): Promise<AuditEntity[]> {
    const res = await this.get(this.endpoint + `/${id}/audits`);
    return res.data.map(a => new AuditEntity(a));
  }
}

export type ImportResponse = {
  success: boolean;
  data: SpecCreateBaseEntity|{id:number; name:string};
  error?: SpecImExportError;
}
export function createImportResponse(res:AxiosResponse): ImportResponse {
  return {
    success: res.data.success,
    data: res.data.data,
    error: res.data.error ? new SpecImExportError(res.data.error) : undefined
  };
}
export type ExportValidationResponse = {
  success: boolean;
  error?: SpecImExportError;
}

export class SpecForIndex {
  public id!:number;
  public companyOrd!:number;
  public metaId!:number;
  public name!:string;
  public brandName!:string;
  public sharedWithCount:number = 0;
  public updatedAt!:string;
  public createdBy!:string;
  public hasReferenceProductUpdate:boolean = false;
  public tags:string[] = [];
  public departments:string[] = [];
  public isForwarding!: boolean;

  constructor(init:SpecForIndex) {
    ObjectUtils.assignLiteralFields(this, init);
    this.tags = init.tags;
    this.departments = init.departments;
  }
}

export type LatestSpecsForSubmission = {
  id:number,
  name:string,
  search:string,
  createdAt: string,
  type:'latest_draft'|'latest_published'|'non_latest_published'
};

export enum ImportDataType {
  SOUKENKUN_STANDARD_XLS_V2_3 = 'soukenkun_standard_xls_v2_3',
  EBASE_STANDARD_FILES = 'ebase_standard_files',
  MERQURIUS_SHEET_V3_1 = 'merqurius_sheet_v3_1',
  INFOMART = 'infomart',
}
export enum ExportDataType {
  PITS = 'pits',
  SOUKENKUN_STANDARD_XLS_V2_3 = 'soukenkun_standard_xls_v2_3',
  EBASE_STANDARD_FILES = 'ebase_standard_files',
  MERQURIUS_SHEET_V3_1 = 'merqurius_sheet_v3_1',
}

export enum ExportTemplateType {
  PREPARED = 'prepared',
  Custom = 'custom'
}
export enum ExportOutputFormat {
  PDF = 'pdf',
  ZIP = 'zip',
  XLS = 'xls'
}
export interface ExportEventArgs {
  type: ExportTemplateType;
  id: ExportDataType|number;
  outputFormat: ExportOutputFormat | CustomExportTemplateOutputExtension;
}
export enum ExportRequestPitsOptionPrintPage {
  Basic = 'basic',
  Ingredients = 'ingredients',
  PackageAndManufacture = 'package_and_manufacture',
  ManufactureProcesses = 'manufacture_processes',
  Nutrition = 'nutrition',
  Attachments = 'attachments',
}
export const ExportRequestPitsOptionPrintPageDict  = {
  [ExportRequestPitsOptionPrintPage.Basic]: '標準商品規格書',
  [ExportRequestPitsOptionPrintPage.Ingredients]: '原材料配合表',
  [ExportRequestPitsOptionPrintPage.PackageAndManufacture]: '包装・製造',
  [ExportRequestPitsOptionPrintPage.ManufactureProcesses]: '製造工程',
  [ExportRequestPitsOptionPrintPage.Nutrition]: '成分詳細',
  [ExportRequestPitsOptionPrintPage.Attachments]: '製造添付資料'
};
export enum ExportRequestPitsOptionAmountVisibility {
  VISIBLE_ALL = 'visible',
  HIDDEN_ALL = 'hidden',
  VISIBLE_ADDITIVE = 'visible_additive',
}
export type ExportRequestPitsOption = {
  printPages: ExportRequestPitsOptionPrintPage[];
  amountVisibility: ExportRequestPitsOptionAmountVisibility;
  showPageNum: boolean;
}
export interface ExportEventArgsOptions {
  arg1?: string;
  pitsOption?: ExportRequestPitsOption;
}
export interface IExportRequest extends ExportEventArgs, ExportEventArgsOptions {

}
