












import {Vue, Component, Prop } from 'vue-property-decorator';
import { HAS_CHANGE_CLASS } from '../../../../spec-comparisons/comparison-entity-type';
import {nl2br} from "@/utils/string-utils";
import isString from "lodash/isString";

@Component
export default class extends Vue {
  @Prop({required: false}) private label!:string|null;
  @Prop({default: false}) private translate!:boolean;
  @Prop({required: false, default: null}) private value!:string|null;
  @Prop({default: null}) public readonly compareProp!:string|string[]|null;
  @Prop({default: false}) private readonly hasChange!:boolean;
  @Prop({required: false}) private compareTableColKey?:string;
  @Prop({default: false}) private dangerouslyUseHtmlString!:boolean;

  private readonly HAS_CHANGE_CLASS = HAS_CHANGE_CLASS;

  private get valueDisplay() {
    if (!this.value) return '-';
    if (isString(this.value)) {
      return nl2br(this.value);
    }
    return this.value;
  }
}
