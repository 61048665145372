














































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  ExportDataType,
  ExportEventArgs, ExportEventArgsOptions,
  ExportOutputFormat,
  ExportTemplateType, ExportValidationResponse,
  IExportRequest,
} from "@/repositories/spec/company/spec-repository";
import SpecExportModal from "@/views/spec/companies/components/SpecExportModal.vue";
import SpecEntity from "@/entities/specs/spec-entity";
import LoadingHandler from "@/utils/loading-handler";
import SpecImExportError from "@/entities/specs/spec-im-export-error";
import {i18n} from "@/bootstraps/locale";
import SpecExportModalPits from "@/views/spec/companies/components/SpecExportModalPits.vue";
import {CompanyCustomExportTemplateRepository} from "@/repositories/company/company-custom-export-template-repository";
import {CustomExportTemplateEntity, CustomExportTemplateResourceType} from "@/entities/custom-export-template-entity";

@Component({
  components: {
    SpecExportModalPits,
    SpecExportModal
  }
})
export default class extends Vue {
  @Prop({required: true}) private spec!:SpecEntity;
  @Prop({required: true}) private companyId!:number;
  @Prop({required: true}) private validate!: (req:ExportEventArgs) => Promise<ExportValidationResponse>;
  @Prop({default: false}) private needReceiverName!:boolean;
  @Prop({default: true}) private showModify!:boolean;

  private readonly TemplateType = ExportTemplateType;
  private readonly DataType = ExportDataType;
  private readonly OutputFormat = ExportOutputFormat;

  private specCustomOutputTemplateList:CustomExportTemplateEntity[]|null = null;

  private dialogArgs:ExportEventArgs|null = null;
  private dialogVisible = false;
  private dialogLabel: string = '';
  private dialogPlaceholder: string = '';

  private pitsDialogVisible = false;

  private validationDialog = {
    visible: false,
    error: null as SpecImExportError | null
  }

  // DELETE_ME: TMP
  private get showAll(): boolean {
    return true;
    // return 'all' in this.$route.query;
  }

  private async created() {
    this.specCustomOutputTemplateList = await (new CompanyCustomExportTemplateRepository(this.companyId)).all(CustomExportTemplateResourceType.OwnSpec);
  }

  private async onSelected(args:ExportEventArgs) {
    if (args.type === this.TemplateType.Custom) {
      this.$emit('submit', args);
      return;
    }

    this.dialogArgs = args;

    if (args.id === ExportDataType.PITS) {
      this.pitsDialogVisible = true;
      return;
    }

    if (args.id === ExportDataType.MERQURIUS_SHEET_V3_1) {
      this.dialogLabel = "MerQurius Net ID";
      this.dialogPlaceholder = i18n.t("例）R12345000001-001");
    } else if (this.needReceiverName) {
      this.dialogLabel = i18n.t("提出先の組織名");
      this.dialogPlaceholder = i18n.t("例）株式会社スマート食品");
    }

    const res = await LoadingHandler(async () => {
      return await this.validate(args);
    }, 180000, {text: this.$t('内容をチェックしています') + `（${this.$t('この処理は数十秒かかる場合があります')}）`});

    if (res.success) {
      this.showInputDialog();
    } else {
      this.validationDialog.error = res.error!;
      this.validationDialog.visible = true;
    }
  }

  private get validationErrors() {
    if(!this.validationDialog.error) return [];

    const basicErrors = this.validationDialog.error.specErrors.map(e => {
      return {
        tab: e.tabView,
        level: e.errorLevel,
        typeLabel: this.$t(e.errorLevel === 'alert' ? '要確認' : '不足'),
        message: e.message,
      }
    });

    const ingErrors = this.validationDialog.error.ingredientErrorMessagesOnExport.flatMap(e => {
      return {
        tab: this.$t('原材料'),
        level: e.level,
        typeLabel: this.$t(e.level === 'alert' ? '要確認' : '不足'),
        message: e.index + '行目の' + e.msg,
      }
    })

    return basicErrors.concat(ingErrors);
  }


  private showInputDialog() {
    if (this.dialogArgs!.id === ExportDataType.MERQURIUS_SHEET_V3_1) {
      this.dialogVisible = true;
    } else if (this.needReceiverName) {
      this.dialogVisible = true;
    } else {
      this.$emit('submit', this.dialogArgs);
    }
  }

  private modify() {
    this.$emit('modify', this.validationDialog.error);
  }

  private submit(args: ExportEventArgsOptions) {
    const data:IExportRequest = {...this.dialogArgs!, ...args};
    this.$emit('submit', data);
  }
}
