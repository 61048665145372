import {i18n} from "@/bootstraps/locale";
import {PreservationMethodLang as langP} from "@/lang/enum/preservation-method-lang";
import {createDecimalRule, createMaxStringRule, createMinRule, requiredOnBlurRule} from "@/utils/validation-rules";

export enum AmountTypeEnum {
  Amount = 1,
  FixAndTotal = 2,
  Fix = 3,
  Hidden = 9,
}

export enum MilkRatioUnitEnum {
  Percent = 1,
  PercentGtE = 2,
  PercentLt = 3,
}
export const MilkRatioUnit = {
  [MilkRatioUnitEnum.Percent]: '%',
  [MilkRatioUnitEnum.PercentGtE]: i18n.t('%以上'),
  [MilkRatioUnitEnum.PercentLt]: i18n.t('%未満'),
} as {[key:number]:string};


export interface ILabelEntity {
  visibleExtraFieldKeys:(LabelExtraFieldEnum | LabelExtraFieldEnumSpec)[];
  precaution:string|null;
  howToCook:string|null;
  usage:string|null;
  sanitizeMethod:string|null;
  heatedBeforeFrozen:string|null;
  needHeating:string|null;
  starchRatio:number|null;
  milkNonfatSolidRatio:number|null;
  milkNonfatSolidRatioUnitType:MilkRatioUnitEnum;
  milkFatRatio:number|null;
  milkFatRatioUnitType:MilkRatioUnitEnum;

  outsideText:string|null;
}

export interface ILabelAmount {
  amountType:AmountTypeEnum;
  amount:string|null;
  solidAmount:string|null;
  totalAmount:string|null;
}

export enum LabelExtraFieldEnum {
  Precaution = 'precaution',
  HowToCook = 'howToCook',
  Usage = 'usage',
  SanitizeMethod = 'sanitizeMethod',
  HeatedBeforeFrozen = 'heatedBeforeFrozen',
  NeedHeating = 'needHeating',
  StarchRatio = 'starchRatio',
  MilkNonfatSolidRatio = 'milkNonfatSolidRatio',
  MilkFatRatio = 'milkFatRatio',
  CustomValues = 'customValues'
}
export enum LabelExtraFieldEnumSpec {
  MadeInCountry = 'madeInCountry',
  MadeInArea = 'madeInArea',
}
export type LabelExtraFieldEnumSpecType = LabelExtraFieldEnum | LabelExtraFieldEnumSpec;
type ExtraFieldCommon = {
  title: string, placeHolder?: string, isCustom?:boolean; type?: string, suffix?: string;
};
export type ExtraField = { key: LabelExtraFieldEnumSpecType } & ExtraFieldCommon;
export const LabelExtraFields:Array<{ key: LabelExtraFieldEnum } & ExtraFieldCommon> = [
  {key: LabelExtraFieldEnum.Precaution, title: i18n.t('使用上の注意'), placeHolder: i18n.t('例）開缶後はガラス等の容器に移し換えること'), },
  {key: LabelExtraFieldEnum.HowToCook,  title: i18n.t('調理方法'), placeHolder: i18n.t('例）加熱調理すること'), },
  {key: LabelExtraFieldEnum.Usage, title: i18n.t('使用方法'), placeHolder: i18n.t('自由入力'), },
  {key: LabelExtraFieldEnum.SanitizeMethod,  title: i18n.t('殺菌方法'), placeHolder: i18n.t('自由入力'), },
  {key: LabelExtraFieldEnum.HeatedBeforeFrozen,  title: i18n.t('凍結前加熱の有無'), placeHolder: i18n.t('例）加熱してありません'), },
  {key: LabelExtraFieldEnum.NeedHeating, title: i18n.t('加熱調理の必要性'), placeHolder: i18n.t('例）加熱してください'), },
  {key: LabelExtraFieldEnum.StarchRatio, title: i18n.t('でん粉含有率'), placeHolder: i18n.t('例）') + '3.5', type: 'number', suffix: '%' },
  {key: LabelExtraFieldEnum.MilkNonfatSolidRatio, title: i18n.t('無脂乳固形分'), isCustom: true },
  {key: LabelExtraFieldEnum.MilkFatRatio, title: i18n.t('乳脂肪分'), isCustom: true, },
  {key: LabelExtraFieldEnum.CustomValues, title: i18n.t('その他の項目'), isCustom: true, },
];
export const LabelExtraFieldsForSpec: Array<{ key: LabelExtraFieldEnumSpecType } & ExtraFieldCommon> = [
  {key: LabelExtraFieldEnumSpec.MadeInCountry, title: i18n.t('原産国名'), placeHolder: i18n.t('自由入力'), },
  {key: LabelExtraFieldEnumSpec.MadeInArea,  title: i18n.t('原料原産地'), placeHolder: i18n.t('自由入力'), },
  ...LabelExtraFields
];

export enum LabelExpirationTypeEnum {
  BestBy = 1,
  Expiration = 2,
  Guarantee = 3,
  ManufacturedAt = 5,
}
export const LabelExpirationTypes = {
  [LabelExpirationTypeEnum.BestBy]: i18n.t('賞味期限'),
  [LabelExpirationTypeEnum.Expiration]: i18n.t('消費期限'),
  [LabelExpirationTypeEnum.Guarantee]: i18n.t('品質保証期限'),
  [LabelExpirationTypeEnum.ManufacturedAt]: i18n.t('製造年月日'),
} as {[key:number]:string};

export enum ResponsiblePattern {
  Manufacturer = 1,
  ManufacturerAndFactory = 2,
  ManufacturerAndFactoryCodes = 3,
  SellerAndManufacturer = 4,
  SellerAndProcessor = 5,
  SellerAndImporter = 6,
  SellerAndFactoryCodes = 7,
  Processor = 8,
  ProcessorAndFactory = 9,
  Importer = 10,
}
export const ResponsiblePatternDict = {
  [ResponsiblePattern.Manufacturer]: i18n.t('製造者'),
  [ResponsiblePattern.ManufacturerAndFactory]: i18n.t('製造者 + 製造所'),
  [ResponsiblePattern.ManufacturerAndFactoryCodes]: i18n.t('製造者 + 製造所固有記号'),
  [ResponsiblePattern.SellerAndManufacturer]: i18n.t('販売者 + 製造所'),
  [ResponsiblePattern.SellerAndProcessor]: i18n.t('販売者 + 加工所'),
  [ResponsiblePattern.SellerAndImporter]: i18n.t('販売者 + 輸入者'),
  [ResponsiblePattern.SellerAndFactoryCodes]: i18n.t('販売者 + 製造所固有記号'),
  [ResponsiblePattern.Processor]: i18n.t('加工者'),
  [ResponsiblePattern.ProcessorAndFactory]: i18n.t('加工者 + 加工所'),
  [ResponsiblePattern.Importer]: i18n.t('輸入者'),
} as {[key:number]:string};


export enum LabelCategoryTypeEnum {
  Meishou= 1,
  Hinmei = 2,
  Hinmoku = 3,
  Shuruibetsu = 4,
  ShuruibetsuMeishou = 5,
}
export const LabelCategoryTypes = {
  [LabelCategoryTypeEnum.Meishou]: i18n.t('名称'),
  [LabelCategoryTypeEnum.Hinmei]: i18n.t('品名'),
  [LabelCategoryTypeEnum.Hinmoku]: i18n.t('品目'),
  [LabelCategoryTypeEnum.Shuruibetsu]: i18n.t('種類別'),
  [LabelCategoryTypeEnum.ShuruibetsuMeishou]: i18n.t('種類別名称')
} as {[key:number]:string};

export const LabelPreservationText = [
  langP["直射日光を避け、保存してください"],
  langP["直射日光を避け、常温で保存してください"],
  langP["直射日光を避け、涼しい場所で保存してください"],
  langP["直射日光を避け、冷蔵庫で保存してください"],
  langP["常温"],
  langP["常温（28℃以下）"],
  langP["要冷蔵"],
  langP["要冷蔵（10℃以下）"],
  langP["要冷蔵（4℃以下）"],
  langP["要冷蔵（-5℃以下）"],
  langP["要冷凍"],
  langP["要冷凍（-15℃以下）"],
  langP["要冷凍（-18℃以下）"],
  langP["常温で保存すること"],
  langP["常温（28℃以下）で保存すること"],
  langP["要冷蔵で保存すること"],
  langP["要冷蔵（10℃以下）で保存すること"],
  langP["要冷蔵（4℃以下）で保存すること"],
  langP["要冷蔵（-5℃以下）で保存すること"],
  langP["要冷凍で保存すること"],
  langP["要冷凍（-15℃以下）で保存すること"],
  langP["要冷凍（-18℃以下）で保存すること"],
];

export const LabelEntityValidatorRules = {
  categoryType: [],
  category: [createMaxStringRule()],
  ingredientsText: [],

  amount: [createMaxStringRule()],
  solidAmount: [createMaxStringRule()],
  totalAmount: [createMaxStringRule()],

  expirationTypes: [],
  expirationText: [createMaxStringRule()],
  preservationMethod: [createMaxStringRule()],
  madeInCountry: [createMaxStringRule()],
  madeInArea: [createMaxStringRule()],
  precaution: [createMaxStringRule()],
  howToCook: [createMaxStringRule()],
  usage: [createMaxStringRule()],
  sanitizeMethod: [createMaxStringRule()],
  heatedBeforeFrozen: [createMaxStringRule()],
  needHeating: [createMaxStringRule()],

  starchRatio: [createMinRule(0), createDecimalRule(6, 3)],
  milkNonfatSolidRatio: [createMinRule(0), createDecimalRule(6, 3)],
  milkFatRatio: [createMinRule(0), createDecimalRule(6, 3)],

  responsibleCompanyName: [createMaxStringRule()],
  responsibleCompanyAddress: [createMaxStringRule()],
  responsibleFactoryName: [createMaxStringRule()],
  responsibleFactoryAddress: [createMaxStringRule()],

  customValues: {
    name: [requiredOnBlurRule, createMaxStringRule()],
    value: [requiredOnBlurRule, createMaxStringRule()],
  }
};

