









































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {ValidateWithScroll} from "@/utils/validation-handler";

import CompanyEntity, {ValidatorRules} from "@/entities/company-entity";
import {AcceptedSpecReconfirmationNotificationTypeDict, AcceptedSpecReconfirmationReminderTypeDict} from "@/entities/company-setting-entity";
import {CompanyRepository, SpecSettingUpdateRequest} from "@/repositories/company-repository";
import AuthViewBase from "@/views/AuthViewBase";

import CanEditButton from "@/views/common/mypage/company/components/CanEditButton.vue";
import SettingCommonSection from "@/views/common/mypage/company/components/SettingCommonSection.vue";
import {help as PopoverText} from '@/lang/help/setting';
import {i18n} from "@/bootstraps/locale";
import { createMinRule, typeNonNegativeIntRule } from '@/utils/validation-rules';
import { RequiredFields } from '@/entities/specs/spec-share-request-entity';
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import MyPageBodySection from "@/views/common/mypage/components/MyPageBodySection.vue";
import MyPageBodySettingRow from "@/views/common/mypage/components/MyPageBodySettingRow.vue";

@Component({
  components: {
    MyPageBodySettingRow,
    MyPageBodySection,
    MypageBodyHeader,
    SettingCommonSection,
    CanEditButton
  }
})
export default class extends AuthViewBase {
  private company:CompanyEntity = null as any;

  private initialized: boolean = false;

  private updateRequest:SpecSettingUpdateRequest = null as any;

  private readonly AcceptedSpecReconfirmationNotificationTypeDict = AcceptedSpecReconfirmationNotificationTypeDict;
  private readonly AcceptedSpecReconfirmationReminderTypeDict = AcceptedSpecReconfirmationReminderTypeDict;
  private readonly RequiredFields = RequiredFields;

  private readonly Popover = PopoverText;

  private get rules() {
    return {
      acceptedSpecReconfirmationNotificationDays: [
        createMinRule(1),
        typeNonNegativeIntRule,
        {
          validator: (rule, value, callback) => {
            if (this.updateRequest.isAcceptedSpecReconfirmationNotificationEnabled && (value === null || value === '')) {
              return callback(new Error(i18n.t('validations.この項目は必須です')));
            }
            return callback();
          }
        }
      ]
    };
  }

  private async created() {
    this.company = await (new CompanyRepository()).findById(this.companyId);

    const s = this.company.setting;
    this.updateRequest = {
      isAutoCreateIngredientFromSpec: s.isAutoCreateIngredientFromSpec,
      isENumberVisible: s.isENumberVisible,
      isDepartmentEnabled: s.isDepartmentEnabled,
      isApprovalFlowEnabled: s.isApprovalFlowEnabled,
      isAccessLogVisible: s.isAccessLogVisible,
      accessibilityToOtherDeptResources: s.accessibilityToOtherDeptResources,
      departments: this.company.departments.slice(),
      isAcceptedSpecReconfirmationNotificationEnabled: s.isAcceptedSpecReconfirmationNotificationEnabled,
      acceptedSpecReconfirmationNotificationType: s.acceptedSpecReconfirmationNotificationType,
      acceptedSpecReconfirmationNotificationDays: s.acceptedSpecReconfirmationNotificationDays,
      acceptedSpecReconfirmationReminderType: s.acceptedSpecReconfirmationReminderType,
      specRequestDefaultRequiredFields: s.specRequestDefaultRequiredFields,
      passwordMinCharLength: s.passwordMinCharLength,
      passwordMinTypeLength: s.passwordMinTypeLength,
      isMfaEmailEnabled: s.isMfaEmailEnabled,
      accessibleIpAddresses: s.accessibleIpAddresses,
    };
    this.initialized = true;
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.form as any))) return;

    const confirmed = await (() => {
      const s = this.company.setting;
      const u = this.updateRequest;
      return new Promise((resolve, reject) => {
        if (
          (u.isAcceptedSpecReconfirmationNotificationEnabled !== s.isAcceptedSpecReconfirmationNotificationEnabled) ||
          (u.acceptedSpecReconfirmationNotificationType !== s.acceptedSpecReconfirmationNotificationType) ||
          (u.acceptedSpecReconfirmationNotificationDays !== s.acceptedSpecReconfirmationNotificationDays)
        ){
          this.$confirm(
            this.$t(`この設定を変更すると、新しい条件に一致する通知が画面上に作成されます（メールは送信されません）`),
            this.$t('「回収規格書の定期確認」の変更'),
            {
              confirmButtonText: this.$t('変更を保存'),
              cancelButtonText: this.$t('キャンセル'),
            }
          ).then(() => {
            resolve(true);
          }).catch(err => {
            if (err !== 'cancel') throw err;
            resolve(false);
          });
        } else {
          return resolve(true);
        }
      });
    })();

    if (confirmed) {
      LoadingHandler(() => {
        return (new CompanyRepository).updateSettingSpec(this.companyId, this.updateRequest);
      }).then(() => {
        this.$message({type: 'success', message: this.$t('規格書設定を更新しました。画面をリロードします')});

        setTimeout(() => location.reload(), 1000);
      });
    }

  }

}
