import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ProductEntity from "@/entities/product-entity";
import {formatPrice, NutritionLayoutTypeKeys} from "@/entities/product-label-layout-entity";
import InlineBorderless from "@/views/label/companies/print/layouts/nutrition/InlineBorderless.vue";
import TableBordered from "@/views/label/companies/print/layouts/nutrition/TableBordered.vue";
import JsBarcode from "jsbarcode";
import Qr from 'qrcode-generator';
import ProductDisplayService from "@/services/product-display-service";
import {AmountTypeEnum, LabelExtraFieldEnum, LabelExtraFields, MilkRatioUnit} from "@/entities/interfaces/i-label-entity";
import {PartnerAllTypeDict, PartnerTypeDict} from "@/entities/specs/partner-entity";
import MarkIconContainer from "@/views/label/companies/print/layouts/components/MarkIconContainer.vue";
import CompanyEntity from "@/entities/company-entity";

@Component({
  components: {
    InlineBorderless,
    TableBordered,
    MarkIconContainer
  }
})
export default class LabelLayoutBase extends Vue  {
  @Prop({required: true}) public product!:ProductEntity;
  @Prop({required: true}) public manufactureDate!:Date;
  @Prop({required: true}) public company!:CompanyEntity;

  protected readonly NutritionLayoutTypeKeys = NutritionLayoutTypeKeys;

  protected showJanCode:boolean = false;
  protected showQrCode:boolean = false;

  @Watch('product', {deep: true})
  private watchModel(val, oldVal) {
    this.initBarcode();
    this.initQrCode();
  }

  private mounted() {
    this.initBarcode();
    this.initQrCode();
  }
  private initBarcode() {
    if (!this.product.productLabelLayout.showJanCode || !this.product.productLabelLayout.janCode) {
      this.showJanCode = false;
      return;
    }

    try {
      JsBarcode(this.$refs.janCode, this.product.productLabelLayout.janCode, {
        format: 'EAN13',
        width: 1,
        height: 15,
        fontSize: 6,
        textMargin: 0,
        margin: 0,
        flat: true
      });
      this.showJanCode = true;
    } catch (err) {
      console.warn(err);
      this.showJanCode = false;

      const errorMessage = typeof err === 'string' ? err : err.message;
      if (!errorMessage || !errorMessage.match(/is not a valid input for/)) {
        throw err;
      }
    }
  }
  private initQrCode() {
    const dom = this.$refs.qrCode as HTMLElement;
    if (!dom) return;

    if (!this.product.productLabelLayout.showQrCode || !this.product.productLabelLayout.qrCode) {
      this.showQrCode = false;
      return;
    }

    const qr = Qr(0, 'M');
    qr.addData(this.product.productLabelLayout.qrCode);
    qr.make();
    dom.innerHTML = qr.createSvgTag({
      cellSize: 1,
      margin: 0
    });
    this.showQrCode = true;
  }

  protected get layout() {
    return this.product.productLabelLayout;
  }
  protected get price() {
    return formatPrice(this.product.price, this.layout);
  }

  protected getLabelValues() {
    const product = this.product;
    const label = product.productLabel;

    const dict:{title:string, value:any}[] = [];
    dict.push({ title: product.productLabel.categoryTypeLabel || '名称', value: product.commonName });
    dict.push({ title: '原材料名', value: new ProductDisplayService(product, product.productDisplaySetting, this.company.setting).getIngredientNames() });

    if (product.productDisplaySetting.isMadeInAreasSeparated) {
      if (product.productLabel.isMadeInAreaOverride) {
        dict.push({ title: '原料原産地名', value: product.productLabel.madeInAreaOverrideText });
      } else {
        dict.push({ title: '原料原産地名', value: (new ProductDisplayService(product, product.productDisplaySetting, this.company.setting)).getMadeInNames('append') });
      }
    }

    if (label.amountType === AmountTypeEnum.Amount) {
      dict.push({ title: '内容量', value: label.amount });
    } else if (label.amountType === AmountTypeEnum.Fix) {
      dict.push({ title: '固形量', value: label.solidAmount });
    } else if (label.amountType === AmountTypeEnum.FixAndTotal) {
      dict.push({ title: '固形量', value: label.solidAmount });
      dict.push({ title: '内容総量', value: label.totalAmount });
    }

    dict.push({
      title: label.expirationTypeLabel,
      value: label.getExpirationLabel(this.manufactureDate, product.productLabelLayout.expirationFormatType) }
    );

    if (label.preservationMethod) {
      dict.push({ title: '保存方法', value: label.preservationMethod });
    }

    dict.push(
      ...LabelExtraFields.filter(i => !i.isCustom && label.visibleExtraFieldKeys.includes(i.key)).map(i => {
        return {
          title: i.title,
          value: (label[i.key] || '') + (i.suffix || '')
        }
      })
    );
    if (label.visibleExtraFieldKeys.includes(LabelExtraFieldEnum.MilkNonfatSolidRatio)) {
      dict.push({
        title: '無脂乳固形分',
        value: label.milkNonfatSolidRatio + MilkRatioUnit[label.milkNonfatSolidRatioUnitType.toString()]
      });
    }
    if (label.visibleExtraFieldKeys.includes(LabelExtraFieldEnum.MilkFatRatio)) {
      dict.push({
        title: '乳脂肪分',
        value: label.milkFatRatio + MilkRatioUnit[label.milkFatRatioUnitType.toString()]
      });
    }
    if (label.visibleExtraFieldKeys.includes(LabelExtraFieldEnum.CustomValues)) {
      label.productLabelCustomValues.forEach(cv => {
        dict.push({
          title: cv.name || '',
          value: cv.value || ''
        });
      });
    }

    return dict;
  }
  protected getPartnerValues():{title:string, name:string|null, address:string }[] {
    const label = this.product.productLabel;

    const responsibleCompany = label.virtualResponsibleCompany || label.responsibleCompany;
    if (!responsibleCompany) return [];

    const telNoSuffix = this.product.productLabel.telno ? `　Tel.${this.product.productLabel.telno}` : '';

    if (label.shouldPrintFactory) {
      return [
        {
          title: PartnerAllTypeDict[label.responsibleCompanyType],
          name: responsibleCompany.name,
          address: responsibleCompany.getDomesticAddress() + telNoSuffix,
        },
        {
          title: PartnerAllTypeDict[label.responsibleFactoryPattern!] || '',
          name: label.showFactoryName ? label.responsibleFactory!.name : null,
          address: label.responsibleFactory!.getDomesticAddress(),
        },
      ];
    } else {
      const name = responsibleCompany.name + label.responsibleFactoryUniqueCodesLabel;
      return [
        {
          title: PartnerTypeDict[label.responsibleCompanyType],
          name: name,
          address: responsibleCompany.getDomesticAddress() + telNoSuffix
        }
      ];
    }
  }
}
