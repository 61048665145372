










































import {Component, Prop} from 'vue-property-decorator';
import execWithLoading from "@/utils/loading-handler";
import AuthViewBase from "@/views/AuthViewBase";
import ElTableDraggable from "@/components/Table/SortableTable/ElTableDraggable.vue";
import LoadingHandler from "@/utils/loading-handler";
import EditNameModal from "@/views/common/mypage/company/components/EditNameModal.vue";
import SortableTable from "@/components/Table/SortableTable/SortableTable.vue";
import MypageBodyHeader from "@/views/common/mypage/components/MypageBodyHeader.vue";
import {CompanyCustomExportTemplateRepository} from "@/repositories/company/company-custom-export-template-repository";
import {
  CustomExportTemplateEntity, CustomExportTemplateOutputExtension,
  CustomExportTemplateResourceType,
  ValidatorRules
} from "@/entities/custom-export-template-entity";
import CustomExportModal from "@/views/common/mypage/company/Setting/CustomExport/CustomExportModal.vue";
import {IFileDefaultValue, IFileUpload} from "@/contracts/i-file";

@Component({
  components: {
    CustomExportModal,
    MypageBodyHeader,
    SortableTable,
    EditNameModal,
    ElTableDraggable,
  }
})
export default class extends AuthViewBase {
  @Prop( { required: true }) resourceType!: CustomExportTemplateResourceType;

  private readonly ValidatorRules = ValidatorRules;
  private templates:CustomExportTemplateEntity[]|null = null;

  private addModal = {
    visible: false,
  };

  private async created() {
    await execWithLoading(async () => {
      await this.load();
    });
  }
  private async load() {
    return Promise.all([
      (new CompanyCustomExportTemplateRepository(this.companyId)).all(this.resourceType).then(list => {
        this.templates = list;
      }),
    ]);
  }

  private row(scope: { row: CustomExportTemplateEntity }) {
    return scope.row;
  }

  private showAddModal() {
    this.addModal = { visible: true };
  }
  private async add( data: { id: number, name: string, template: IFileUpload, outputExt: CustomExportTemplateOutputExtension, } ) {
    return LoadingHandler(async () => {
      const req = new CustomExportTemplateEntity(data);
      await (new CompanyCustomExportTemplateRepository(this.companyId)).add(req, this.resourceType);
    }).then(() => {
      this.addModal.visible = false;
      this.$message({ type: 'success', message: this.$t("追加しました") });
      this.load();
    });
  }

  private showDeleteModal(row: CustomExportTemplateEntity) {
    const msg = '本当に削除してもよろしいですか。';
    this.$confirm(msg, 'オリジナルフォーマットの削除', {
      customClass: 'danger',
      confirmButtonText: this.$t('同意して削除'),
      confirmButtonClass: 'c-button danger no-focus',
      cancelButtonText: this.$t('キャンセル'),
      cancelButtonClass: 'c-button danger-inverse no-focus',
      dangerouslyUseHTMLString: true
    })
      .then(async () => {
        await LoadingHandler(async () => {
          await (new CompanyCustomExportTemplateRepository(this.companyId)).remove(row.id);
          await this.load();
        });
      })
      .catch(err => { if (err !== 'cancel' && err !== 'close' ) throw err; });
  }


  private changeOrder( row: CustomExportTemplateEntity, order:number) {
    return LoadingHandler(async () => {
      await (new CompanyCustomExportTemplateRepository(this.companyId)).updateSortOrder(row.id, order);
      await this.load();
    });
  }

  private get helpLink() {
    if (this.resourceType === "product") {
      return "https://docs.google.com/spreadsheets/d/1WQ25VYE1_i7-UWUqx14e5pnIoghPH9BUuk4O0Klinfc/edit?gid=0#gid=0";
    }

    return null;
  }
}
