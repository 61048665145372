import {Component, Prop, Vue} from 'vue-property-decorator';
import OnFileSelectedHandler, {ReadAsType} from "../OnFileSelectedHandler";
import IFile from "@/contracts/i-file";
import * as FileSaver from "file-saver";

@Component
export default class extends Vue {
  @Prop({required: true}) protected value!: IFile;
  @Prop({required: false, default: false}) protected allowPdf!: boolean;
  @Prop({type: Number, default: 20}) private maxFileSizeMB!: number;
  @Prop({default: null}) private accepts!: string[] | null;
  @Prop({default: ReadAsType.DataURL}) private readAsType!: ReadAsType;
  @Prop({default: false}) private readonly!: boolean;

  protected attachmentError: string = '';

  private get accept() {
    return this.mime.join(',');
  }

  private get mime(): string[] {
    if (this.accepts) return this.accepts;

    return this.allowPdf ?
      ['image/png', 'image/gif', 'image/jpeg', 'application/pdf'] :
      ['image/png', 'image/gif', 'image/jpeg'];
  }

  protected onInputChanged(evt: any) {
    if (evt.target.files.length === 0) return;
    return this.onFileSelected(evt.target.files).finally(() => {
      evt.target.value = '';
    });
  }

  protected onFileDropped(evt: DragEvent) {
    if (!evt.dataTransfer || evt.dataTransfer.files.length === 0) return;
    this.onFileSelected(evt.dataTransfer.files).finally(() => {
      this.isDragOver = false;
    });
  }

  protected onFileSelected(files) {
    const filename = files[0].name;

    const handler = new OnFileSelectedHandler({
      maxFileSize: this.maxFileSizeMB * 1024 * 1024,
      availableFileTypeMimeList: this.mime,
    }, this.readAsType);
    return handler.onFileSelected(files)
      .then((res: any) => {
        this.onFileLoaded(filename, (res.target as FileReader).result);
        this.attachmentError = '';
      })
      .catch((err) => {
        this.attachmentError = err;
      });
  }

  protected isDragOver = false;

  protected onDragOver() {
    this.isDragOver = true;
  };

  protected onDragLeave() {
    this.isDragOver = false;
  };

  protected onFileLoaded(filename: string | ArrayBuffer | null, result) {
    this.$emit('input', {filename: filename, body: result});
  }

  public get valueExt(): string {
    return this.value.filename.split('.').pop() || '';
  }

  public get isImage() {
    return ['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(this.valueExt);
  }

  protected onRemoved() {
    this.$emit('remove', this.value);
  }

  protected showImage() {
    if (!this.value) return;
    if (this.value.url) {
      window.open(this.value.url, '_blank');
    }
  }
}

export const Mimes = {
  Xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
} as const;
