import {requiredRule, typeTelno, createMaxRule, typeEmailRule, createMaxStringRule} from '@/utils/validation-rules';
import UserEntity from "@/entities/user-entity";
import InvitingMemberEntity from "@/entities/inviting-member-entity";
import ObjectUtils from "@/utils/object-utils";
import { PartnerBase, ValidatorRules as PartnerValidatorRule } from './specs/partner-entity-base';
import {ApprovalService} from "@/services/approval-service";
import {CompanySettingEntity} from "@/entities/company-setting-entity";
import CompanyDepartmentEntity from "@/entities/company-department-entity";
import PartnerIntraCustomValueSettingEntity from "@/entities/partner-intra-custom-value-setting-entity";
import PartnerFactoryIntraCustomValueSettingEntity from "@/entities/partner-factory-intra-custom-value-setting-entity";
import { ApprovalFlowSettingEntity } from "@/entities/approval-flow-setting-entity";
import {Permission, CompanyPermissionEntity} from "@/entities/company-permission-entity";

export class CompanyBasicEntity extends PartnerBase {
  public uid!:string;
  public representativeEmail:string = '';
}

export default class CompanyEntity extends CompanyBasicEntity {
  public setting!:CompanySettingEntity;

  public members:UserEntity[] = [];
  public invitingMembers:InvitingMemberEntity[] = [];

  public departments:CompanyDepartmentEntity[] = [];
  public partnerIntraCustomValueSettings: PartnerIntraCustomValueSettingEntity[] = [];
  public partnerFactoryIntraCustomValueSettings: PartnerFactoryIntraCustomValueSettingEntity[] = [];

  public approvalFlowSettings: ApprovalFlowSettingEntity[] = [];
  public permissions: CompanyPermissionEntity[] = [];

  constructor(init:Partial<CompanyEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);

    if (init.members) {
      this.members = init.members.map(m => new UserEntity(m));
    }

    if (init.setting) {
      this.setting = new CompanySettingEntity(init.setting);
    }
    if (init.departments) {
      this.departments = init.departments;
    }
    if (init.invitingMembers) {
      this.invitingMembers = init.invitingMembers.map(m => new InvitingMemberEntity(m));
    }
    if (init.partnerIntraCustomValueSettings) {
      this.partnerIntraCustomValueSettings = init.partnerIntraCustomValueSettings.map(m => new PartnerIntraCustomValueSettingEntity(m));
    }
    if (init.partnerFactoryIntraCustomValueSettings) {
      this.partnerFactoryIntraCustomValueSettings = init.partnerFactoryIntraCustomValueSettings.map(m => new PartnerFactoryIntraCustomValueSettingEntity(m));
    }
    if (init.approvalFlowSettings) {
      this.approvalFlowSettings = init.approvalFlowSettings.map(m => new ApprovalFlowSettingEntity(m));
    }
    if (init.permissions) {
      this.permissions = init.permissions.map(m => new CompanyPermissionEntity(m));
    }
  }

  public getDomesticAddress() {
    return (this.prefecture || '') + (this.address || '') + (this.building || '');
  }
  public getFullAddress() {
    return (this.country ? this.country + ' / ' : '') + (this.prefecture || '') + (this.address || '') + (this.building || '');
  }

  // ここに置くのは適切ではないと思うが、とりあえず
  public getDecimalPoint(nutritionKey:string) {
    return nutritionKey === 'calorie' ? this.setting.decimalPlaceCalorie : this.setting.decimalPlaceNonCalorie;
  }

  public approvalSettingEnabled(forSubmission:boolean): boolean {
    return (new ApprovalService(this, forSubmission)).approvalSettingEnabled();
  }

  public get approvalFlowSettingsForAcceptance() {
    return this.approvalFlowSettings.filter(m => m.forAcceptance);
  }
  public get approvalFlowSettingsForOwnedSpec() {
    return this.approvalFlowSettings.filter(m => !m.forAcceptance);
  }

  public get can() {
    return {
      customCustomExportProduct: this.permissions.some(p => p.permission === Permission.LabelCustomExportProduct),
      customCustomExportOwnSpec: this.permissions.some(p => p.permission === Permission.LabelCustomExportOwnSpec),
    }
  };
}

export const ValidatorRules = Object.assign({}, PartnerValidatorRule, {
  nameKana: [requiredRule, createMaxStringRule()],
  telno: [requiredRule, typeTelno],
});

export enum CompanyResourceTagType {
  INGREDIENT = 1, // 原材料
  PREPRODUCT = 2, // 中間原材料
  PRODUCT = 3, // 商品
  SPEC_META = 4, // 自社規格書
  SPEC_ACCEPTANCE = 5, // 回収規格書&手入力規格書
}
