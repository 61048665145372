export const intraCategory =
  `現時点では、「自社商品カテゴリ」を設定することができます。

「自社商品カテゴリ」は、主にデータを出力する際の、並び替え、印刷設定などの目的で使用することができます。

「自社商品カテゴリ」の作成・編集は、「管理メンバー」「編集メンバー」に該当するユーザーが可能です。`.replace(/\n/g, '<br>');

export const hiddenWhenAllSameNameIngredientInvisible =
`商品画面の計算結果のうち「原材料名」について、原材料の非表示の条件をどのように行うかを設定することができます。

◆ 同名のうち一つでも非表示があれば

同じ原材料名のうち、一つでも「非表示」設定のものがあれば、計算結果から非表示となります。

◆ 同名のうちすべてが非表示であれば

同じ原材料名のうち、すべてが「非表示」あれば、計算結果から非表示となります。`.replace(/\n/g, '<br>');

export const hiddenWhenAllSameNameAdditiveInvisible =
`商品画面の計算結果のうち「原材料名」について、添加物の非表示の条件をどのように行うかを設定することができます。

◆ 同名のうち一つでも非表示があれば

同じ添加物名のうち、一つでも「非表示」設定のものがあれば、計算結果から非表示となります。

◆ 同名のうちすべてが非表示であれば

同じ添加物名のうち、すべてが「非表示」あれば、計算結果から非表示となります。`.replace(/\n/g, '<br>');

export const gmo2023 =
  `日本の「遺伝子組換えでない」に対する意味が、2022年度以前と2023年度以降で異なります。

法令改正の詳細については、<a class='u-link' href='https://blog.smashoku.com/ja/jp-gmo-regulation-2023_and_comply/' target="_blank">コチラ</a>をご覧ください。

この改正に伴って、当システムでは、2023年4月以前の「遺伝子組み換え」を「（廃止予定）遺伝子組換えでない（2023年度改正前基準）」に振り替えを行いました。

お手数をおかけしますが、「（廃止予定）遺伝子組換えでない（2023年度改正前基準）」の項目については、取引先に確認を取るなどを行った上で、実情に沿って「分別生産流通管理済み（混入率：5％以下）」「遺伝子組換えでない（混入率：0％）」などに選択し直してください。

また、この選択肢が「適用しない」の場合、選択肢「（廃止予定）遺伝子組換えでない（2023年度改正前基準）」については「遺伝子組み換えでない」と表示され、「適用する」を選択した場合は「分別生産流通管理済み」として、表示が行われます。

食品事故防止のため、2023年4月以降は、基本的に「適用する」としてください。

なお、「（廃止予定）遺伝子組換えでない（2023年度改正前基準）」については、2025年3月末時点で「分別生産流通管理済み（混入率：5％以下）」に振り分けられる予定です。`.replace(/\n/g, '<br>');

export const accessibilityToOtherDeptResources =
`部署管理を有効にしている場合、部署間のデータの閲覧・編集権限を設定することができます。

メンバーの所属部署に「管理」が設定されている場合は、この設定に関わらず、「編集メンバー」であれば、全データへの閲覧・編集ができ、「閲覧メンバー」であれば、全データに対して閲覧をすることができます。

◆ 全データを閲覧可能、編集可能

所属部署に関わらず、全データを閲覧でき、編集メンバーであれば、編集することができます。

◆ 全データを閲覧可能、所属外データの編集不可

所属部署に関わらず、全データを閲覧することができます。しかし、所属外の部署であれば、「編集メンバー」であったとしても、編集することはできません。

◆ 所属外データを非表示、編集不可、引用可能

所属部署以外のデータを非表示にします。しかし、直接のURLから閲覧したり、引用することは可能です。また「編集メンバー」であったとしても、編集することはできません。

◆ 所属外データを閲覧不可、編集不可、引用不可

所属部署以外のデータを閲覧不可にします。また、引用することもできません。「編集メンバー」であったとしても、編集することはできません。`.replace(/\n/g, '<br>');

export const memberDepartments =
`このメンバーの所属部署を設定することができます。

所属部署をメンバーと規格書・食品表示などに設定することにより、他部署の閲覧を制限することができます。

部署機能がONの場合、最低でも1部署を設定する必要があります。

注：「管理」について

「管理」に所属しているメンバーは、他の部署に関係なく、すべての規格書・商品を閲覧することができます。

また管理メンバーは「管理」という部署が強制的に割り当てられ、外すことはできません。`.replace(/\n/g, '<br>');

export const resourceDepartments =
`このデータの管理部署を選択してください。

指定がないときは、管理メンバーのみ閲覧することができます。

メンバーがどの部署に所属しているかについて確認・設定を行う場合は、管理メンバーが、画面右上の「メンバー名」>「組織アカウント設定」>「メンバーの追加・管理」から行ってください。`.replace(/\n/g, '<br>');

export const reconfirmationNotification =
`規格書が更新されてから、一定期間が経過した際、規格書の最新状態を確認する目的で通知します。

更新の判断基準となる日付は、以下から選択することが可能です。

・最終更新日（スマート食品規格書を通して受け取った規格書の最終配信日、手動の場合は管理情報を除く規格書の最終修正日）
・適用日（該当の規格書の内容が、有効となった日）`.replace(/\n/g, '<br>');

export const reconfirmationReminder =
`回収規格書一覧の「最新状態確認」の通知に対して、「通知削除」が行われない場合、メールにて確認を促すことができます。

メール配信先のアドレスは、下記の通りです。

宛先: 担当者（設定されていない場合は部署。いずれも設定されていない場合は組織の管理メンバーに配信されます）
cc: 部署、管理者`.replace(/\n/g, '<br>');


export const specRequestDefaultRequiredFields =
`規格書の回収依頼の際、「あなたの組織で必ず必要となる規格書の必須項目」の初期設定をすることができます。（こちらを設定しない場合の必須事項は、画面「自社規格書」をクリックし、画面右上「+ 規格書の作成」からご確認ください。）

実際に提出依頼をする際は、ここの初期設定から変更することもできます。

注意点として、ここで設定されたものは、提出側が何かしらの値を入力・選択しなければ、提出自体ができなくなります。

例えば、「成分：ナトリウム」を設定すると、ナトリウム量を設定していない企業には、ナトリウム量を計算してもらう必要などが出てきますので、ご注意ください。`.replace(/\n/g, '<br>');


export const approvalFlowForAcceptanceEnabled =
`回収管理において、先方から送られてきた規格書について「問題がないため受領」ボタンを押す際、「承認ワークフロー」を適用するかどうかを設定することができます。

◆ 適用しない場合

規格書を確認したユーザーが単独で「問題がないため受領」ボタンを押すことができます。`.replace(/\n/g, '<br>');

export const approvalFlowForAcceptanceIsEditableOnProgress =
`規格書の回収管理において、手動回収の場合、規格書の編集が可能です。これの保存時の承認ワークフロー進行中に、規格書の編集を許可するかどうかの設定となります。

◆ 編集を「許可する」とした場合

編集を行った場合、現在進めている承認フローは中断されます。

また中断された旨が関係者にメールで通知されます。`.replace(/\n/g, '<br>');

export const approvalFlowForOwnedSpecEnabled =
`自社規格書を正規の版として保存する際に「承認ワークフロー」を適用するかどうかを設定することができます。

◆ 適用しない場合

自社規格書を編集しているユーザーが単独で「正規の版として保存」ボタンを押すことが可能です。`.replace(/\n/g, '<br>');

export const approvalFlowForOwnedSpecIsEditableOnProgress =
  `自社規格書を正規の版として保存するための承認ワークフロー進行中に、規格書の編集を許可するかどうかの設定となります。

◆ 編集を「許可する」とした場合

編集を行った場合、現在進めている承認フローは中断されます。

また中断された旨が関係者にメールで通知されます。`.replace(/\n/g, '<br>');

export const approvalFlowDepartments =
`ここで組織の部署を紐付けると、該当の部署のユーザーは、規格書においてこの「承認ワークフロー」を開始することができます。

一つの承認ワークフローに複数の部署を設定することが可能です。

逆に一つの部署で複数の「承認ワークフロー」が紐付いている場合、ユーザーがいずれかの「承認ワークフロー」を選択することができます。

いずれの「承認ワークフロー」も選択しない場合は、「デフォルト」の「承認ワークフロー」が適用されます。`.replace(/\n/g, '<br>');

export const approvalFlowStepCompletionCondition =
`この承認ステップを完了するための条件を設定することができます。

◆ すべての承認グループの場合

下記に設定するすべての承認グループの承認が完了することで、この承認ステップを完了することができます。

◆ いずれか一つの承認グループの場合

下記に設定するいずれかの承認グループの承認が完了することで、この承認ステップを完了することができます。

◆ この承認ステップの完了後

この承認ステップの次の承認ステップが存在する場合、次の承認ステップに設定されたユーザーに「承認が必要である旨」がメールにて通知されます。.`.replace(/\n/g, '<br>');

export const passwordMinCharLength =
`パスワードを設定する際のルールを選択してください。このルールは、組織内のすべてのユーザーに適用されます。

変更後、ルールに適していないパスワードが生じる可能性があるため、一度変更する必要があります。`.replace(/\n/g, '<br>');

export const isMfaEmailEnabled = `組織内のユーザーが、ログイン時に多要素認証を必要とするかどうかを設定してください。`;

export const accessibleIpAddresses =
`こちらをONにすることにより、アカウントにアクセスできるネットワークIPを制限することができます。

会社、テレワークを行うユーザーの自宅などのネットワークIPを指定してください。

指定がない場合は、すべてのIPからアクセスできます。

IPv4のみ指定可能です。またサブネットマスクは指定できません。
また、192.x.x.x、172.x.x.x、10.x.x.xのようなプライベートIPは指定できません。

例: 65.9.42.25

誤って指定してサイトにアクセスできなくなった場合は、運営までお問い合わせください。`.replace(/\n/g, '<br>');
