





























































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import {
  LabelPrintSettingEntity,
  LabelPrintSettingEntityValidatorRules,
  LabelPrintSettingProductEntity,
} from "@/entities/label-print-setting-entity";
import {LabelSheetEntity} from "@/entities/label-sheet-entity";
import {LabelSheetRepository} from "@/repositories/master/label-sheet-repository";
import {LabelPrintSettingRepository} from "@/repositories/company/label-print-setting-repository";
import {help as PopoverText} from "@/lang/help/label-print";
import ProductRepository from "@/repositories/company/product-repository";
import {MessageBoxData, MessageBoxInputData} from "element-ui/types/message-box";
import VueScrollTo from "vue-scrollto";
import isToday from 'date-fns/isToday';
import {ValidateWithScroll} from "@/utils/validation-handler";
import AuthViewBase from "@/views/AuthViewBase";
import FadeOut from "@/components/FadeOut.vue";
import ElTableDraggable from "@/components/Table/SortableTable/ElTableDraggable.vue";
import {dispatchInitialized} from "@/libs/cypress";

@Component({
  components: {
    ElTableDraggable,
    FadeOut,
  }
})
export default class extends AuthViewBase {
  private readonly PopoverText = PopoverText;
  private readonly rules = LabelPrintSettingEntityValidatorRules;

  private printSettings: LabelPrintSettingEntity[] = null as any;
  private labelSheets: LabelSheetEntity[] = null as any;
  private products: { id: number, name: string }[] = null as any;

  private printModal = {
    settingIndex: 0,
    printSettingProduct: null as LabelPrintSettingProductEntity | null,
    visible: false,
    manufactureDate: new Date(),
    isManufactureDateToday: function() {
      return isToday(this.manufactureDate);
    }
  };

  private initialized = false;
  private renewing = false;

  @Watch('printSettings', {deep: true})
  private watchModel(val, oldVal) {
    if (!this.initialized) return;

    this.$nextTick(async () => {
      for(let f of this.$refs.form as any) {
        if (!(await ValidateWithScroll(f as any))) {
          return false;
        }
      }
      this.saveSetting();
    });
  }

  private async saveSetting() {
    this.renewing = true;
    await (new LabelPrintSettingRepository(this.companyId)).upsert(this.printSettings as LabelPrintSettingEntity[]);
  }

  private async created() {
    await LoadingHandler(() => {
      return Promise.all([
        (new LabelSheetRepository()).all().then(res => {
          this.labelSheets = res;
        }),
        (new LabelPrintSettingRepository(this.companyId)).all().then(res => {
          this.printSettings = res;
        }),
        (new ProductRepository(this.companyId)).list().then(res => {
          this.products = res;
        })
      ])
    }).then(() => {
      this.fillInvisibleSelection();
      this.initialized = true;
      dispatchInitialized();
    });
  }

  private getSheets(setting: LabelPrintSettingEntity) {
    if (setting.isBrother) {
      return this.labelSheets.filter(s => s.forBrother);
    }
    return this.labelSheets;
  }

  private fillInvisibleSelection() {
    this.printSettings!.forEach(setting => {
      setting.labelPrintSettingProducts.forEach(settingProduct => {
        const pid = settingProduct.productId;
        if (!this.products!.find(p => p.id === pid)) {
          this.products!.push({id: pid, name: `※他部署の商品により表示できません（商品ID: ${pid}）`});
        }
      });
    });
  }

  private addSetting(type: 'default' | 'brother' = 'default') {
    this.printSettings!.push(new LabelPrintSettingEntity({
      id: null,
      name: this.$t('新しい印刷パターン'),
      labelSheetId: null as any,
      type: type,
      labelPrintSettingProducts: [
        new LabelPrintSettingProductEntity({
          productId: null as any,
          count: 1
        })
      ]
    }));

    this.$nextTick(() => {
      this.scrollToLastSetting()
    });
  }

  private scrollToLastSetting() {
    VueScrollTo.scrollTo(
      document.querySelector('.print-section:last-of-type')
    );
  }

  private cloneSetting(setting: LabelPrintSettingEntity) {
    const created = new LabelPrintSettingEntity(Object.assign({}, setting, {
      id: null,
      name: setting.name + ' のコピー'
    }));
    this.printSettings!.push(created);
    this.$nextTick(() => {
      this.scrollToLastSetting()
    });
  }

  private deleteSetting(setting: LabelPrintSettingEntity) {
    this.printSettings!.splice(
      this.printSettings!.indexOf(setting), 1
    );
  }

  private showEditSettingNameModal(setting: LabelPrintSettingEntity) {
    this.$prompt(
      this.$t('印刷パターン名を変更してください。'),
      this.$t('印刷パターン名の変更'),
    {
      confirmButtonText: this.$t('以上で保存'),
      confirmButtonClass: 'c-button primary no-focus',
      cancelButtonText: this.$t('閉じる'),
      cancelButtonClass: 'c-button primary-inverse no-focus',
      inputValue: setting.name,
    })
      .then(async (data: MessageBoxData) => {
        setting.name = (data as MessageBoxInputData).value;
      }).catch(() => {})
  }

  private addProduct(setting: LabelPrintSettingEntity) {
    setting.labelPrintSettingProducts.push(new LabelPrintSettingProductEntity({
      productId: null as any,
      count: 1
    }));
  }

  private canPrintLabelBySetting(printSetting: LabelPrintSettingEntity): boolean {
    if(!printSetting.labelSheetId) return false;
    if(printSetting.labelPrintSettingProducts.length === 0) return false;
    return true;
  }
  private async showPrintModal(idx:number, printSetting: LabelPrintSettingEntity, product: LabelPrintSettingProductEntity | null = null) {
    const form = (this.$refs.form[idx] as any);
    if(!(await ValidateWithScroll(form as any))) return;

    const settingIndex = this.printSettings!.indexOf(printSetting)!;
    if (this.printSettings![settingIndex]!.labelPrintSettingProducts.length === 0) return;

    this.printModal.settingIndex = settingIndex;
    this.printModal.printSettingProduct = product;
    this.printModal.visible = true;
  }

  private async printLabel() {
    if (!this.printModal.manufactureDate) return;

    if (this.printModal.printSettingProduct) {
      this.openPrint(this.printModal.printSettingProduct!.productId + ":" + this.printModal.printSettingProduct!.count);
    } else {
      const setting = this.printSettings![this.printModal.settingIndex]!;
      this.openPrint( setting.labelPrintSettingProducts.map(p => p.productId + ":" + p.count).join(',') );
    }
  }
  private openPrint(items:string) {
    const setting = this.printSettings![this.printModal.settingIndex]!;
    const url = this.$router.resolve({
      name: 'print-label',
      params: {
        labelSheetId: setting.labelSheetId.toString(),
        type: setting.type,
        manufactureDateTimestamp: this.printModal.manufactureDate.getTime().toString(),
        items: items,
      },
    });
    window.open(url.href, '_blank');
    this.printModal.visible = false;
  }

  private get showBrother(): boolean {
    return this.$auth.user.companySetting.enableBrotherPrint;
  }
}
