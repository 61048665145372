import ObjectUtils from "@/utils/object-utils";
import {FactoryType, FactoryTypeDict, PartnerType, PartnerTypeDict} from "@/entities/specs/partner-entity";
import ProductEntity from "@/entities/product-entity";
import ProductDisplayService from "@/services/product-display-service";
import {CompanySettingEntity} from "@/entities/company-setting-entity";
import {
  AmountTypeEnum,
  ILabelAmount,
  ILabelEntity,
  LabelCategoryTypeEnum,
  LabelCategoryTypes,
  LabelExpirationTypeEnum,
  LabelExpirationTypes,
  LabelExtraFieldEnum,
  LabelExtraFieldEnumSpec, LabelExtraFieldEnumSpecType,
  MilkRatioUnitEnum,
  ResponsiblePattern
} from "@/entities/interfaces/i-label-entity";
import cloneDeep from "lodash/cloneDeep";
import {i18n} from "@/bootstraps/locale";

export default class SpecLabelEntity implements ILabelEntity, ILabelAmount {
  public id!:number;

  public categoryType:LabelCategoryTypeEnum|null = null;
  public category:string|null = null;
  public ingredientsText:string|null = null;

  public amountType:AmountTypeEnum = AmountTypeEnum.Amount;
  public amount:string|null = null;
  public solidAmount:string|null = null;
  public totalAmount:string|null = null;

  public expirationType:LabelExpirationTypeEnum|null = null;
  public expirationText:string|null = null;
  public preservationMethod:string|null = null;
  public madeInCountry:string|null = null;
  public madeInArea:string|null = null;

  public precaution:string|null = null;
  public howToCook:string|null = null;
  public usage:string|null = null;
  public sanitizeMethod:string|null = null;
  public heatedBeforeFrozen:string|null = null;
  public needHeating:string|null = null;

  public starchRatio:number|null = null;
  public milkNonfatSolidRatio:number|null = null;
  public milkNonfatSolidRatioUnitType:MilkRatioUnitEnum = MilkRatioUnitEnum.Percent;
  public milkFatRatio:number|null = null;
  public milkFatRatioUnitType:MilkRatioUnitEnum = MilkRatioUnitEnum.Percent;

  public outsideText:string|null = null;
  public note:string|null = null;

  public responsiblePattern:ResponsiblePattern|null = null;
  public responsibleCompanyName:string|null = null;
  public responsibleCompanyAddress:string|null = null;
  public responsibleFactoryName:string|null = null;
  public responsibleFactoryAddress:string|null = null;
  public responsibleFactoryUniqueCodes:string[] = [];

  public visibleExtraFieldKeys!:LabelExtraFieldEnumSpecType[];

  constructor(init:Partial<SpecLabelEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.responsibleFactoryUniqueCodes = init.responsibleFactoryUniqueCodes || [];
    this.visibleExtraFieldKeys = init.visibleExtraFieldKeys || [];
  }

  public get categoryTypeLabel():string {
    if(!this.categoryType) return '';
    return LabelCategoryTypes[this.categoryType] || '';
  }
  public get expirationTypeLabel(): string|null {
    if (this.expirationType === null) return null;
    return LabelExpirationTypes[this.expirationType];
  }

  public getResponsibleCompanyType():PartnerType|null {
    if (this.responsiblePattern === null) return null;

    switch (this.responsiblePattern) {
      case ResponsiblePattern.Manufacturer:
      case ResponsiblePattern.ManufacturerAndFactory:
      case ResponsiblePattern.ManufacturerAndFactoryCodes:
        return PartnerType.Manufacturer;
      case ResponsiblePattern.Processor:
      case ResponsiblePattern.ProcessorAndFactory:
        return PartnerType.Processor;
      case ResponsiblePattern.Importer:
        return PartnerType.Importer;
      default:
        return PartnerType.Seller;
    }
  }
  public getResponsibleCompanyTypeLabel(): string {
    const type = this.getResponsibleCompanyType();
    if (type === null) return '';
    return PartnerTypeDict[type];
  }
  public getResponsibleCompanyValueLabel(): string|null {
    return (this.responsibleCompanyName || '') + (this.responsibleCompanyAddress || '');
  }

  public getResponsibleFactoryType():FactoryType|null {
    switch (this.responsiblePattern) {
      case ResponsiblePattern.ManufacturerAndFactory:
      case ResponsiblePattern.SellerAndManufacturer:
        return FactoryType.FactoryManufacturer;
      case ResponsiblePattern.ProcessorAndFactory:
      case ResponsiblePattern.SellerAndProcessor:
        return FactoryType.FactoryProcessor;
      case ResponsiblePattern.SellerAndImporter:
        return FactoryType.Importer;
      default:
        return null;
    }
  }
  public getResponsibleFactoryTypeLabel(): string|null {
    const type = this.getResponsibleFactoryType();
    if (type === null) return null;
    return FactoryTypeDict[type];
  }
  public getResponsibleFactoryValueLabel(): string|null {
    return (this.responsibleFactoryName || '') + (this.responsibleFactoryAddress || '');
  }
  public get shouldShowFactory(): boolean {
    return this.getResponsibleFactoryType() !== null;
  }
  public get shouldShowFactoryCodes(): boolean {
    if (this.responsiblePattern === null) return false;
    return [
      ResponsiblePattern.ManufacturerAndFactoryCodes,
      ResponsiblePattern.SellerAndFactoryCodes,
    ].includes(this.responsiblePattern);
  }
  public get responsibleFactoryUniqueCodesLabel():string {
    return this.responsibleFactoryUniqueCodes.filter(u => !!u).map(c => `+${c}`).join(' ');
  }

  public importFromProduct(product:ProductEntity, companySetting: CompanySettingEntity): SpecLabelEntity {
    const label= product.productLabel;

    this.ingredientsText = (new ProductDisplayService(product, product.productDisplaySetting, companySetting)).getIngredientNames();
    this.categoryType = label.categoryType;
    this.category = product.commonName;
    this.amountType = label.amountType;
    this.amount = label.amount;
    this.solidAmount = label.solidAmount;
    this.totalAmount = label.totalAmount;
    this.expirationType = label.expirationType;
    this.expirationText = label.expirationText;
    this.preservationMethod = label.preservationMethod;
    this.madeInCountry = '';
    this.madeInArea = '';
    this.precaution = label.precaution;
    this.howToCook = label.howToCook;
    this.usage = label.usage;
    this.sanitizeMethod = label.sanitizeMethod;
    this.heatedBeforeFrozen = label.heatedBeforeFrozen;
    this.needHeating = label.needHeating;
    this.starchRatio = label.starchRatio;
    this.milkNonfatSolidRatio = label.milkNonfatSolidRatio;
    this.milkNonfatSolidRatioUnitType = label.milkNonfatSolidRatioUnitType;
    this.milkFatRatio = label.milkFatRatio;
    this.milkFatRatioUnitType = label.milkFatRatioUnitType;
    this.outsideText = label.outsideText;

    this.visibleExtraFieldKeys = label.visibleExtraFieldKeys;

    this.responsiblePattern = label.responsiblePattern;
    this.responsibleCompanyName = label.virtualResponsibleCompany.name;
    this.responsibleCompanyAddress = label.virtualResponsibleCompany.getDomesticAddress();
    this.responsibleFactoryUniqueCodes = label.responsibleFactoryUniqueCodes;
    if (label.responsibleFactory) {
      this.responsibleFactoryName = label.responsibleFactory.name;
      this.responsibleFactoryAddress = label.responsibleFactory.getDomesticAddress();
    }

    return this;
  }

  public getValueByKey(key: LabelExtraFieldEnumSpecType): any {
    if (!this.visibleExtraFieldKeys.includes(key)) {
      return null;
    }
    const prop = VisibleExtraFieldKeysPropMap[key];
    if (!prop) throw new Error('Invalid key');
    return this[prop];
  }

  public serialize(): SpecLabelEntity {
    const data = cloneDeep(this) as SpecLabelEntity;

    Object.keys(VisibleExtraFieldKeysPropMap).forEach((key) => {
      const prop = VisibleExtraFieldKeysPropMap[key];
      if (!prop) return;

      if (!this.visibleExtraFieldKeys.includes(key as LabelExtraFieldEnumSpecType)) {
        data[prop] = null;
      }
    });
    return data;
  }
}

export const VisibleExtraFieldKeysPropMap: Record<LabelExtraFieldEnumSpecType, string | undefined> = {
  [LabelExtraFieldEnum.Precaution]: 'precaution',
  [LabelExtraFieldEnum.HowToCook]: 'howToCook',
  [LabelExtraFieldEnum.Usage]: 'usage',
  [LabelExtraFieldEnum.SanitizeMethod]: 'sanitizeMethod',
  [LabelExtraFieldEnum.HeatedBeforeFrozen]: 'heatedBeforeFrozen',
  [LabelExtraFieldEnum.NeedHeating]: 'needHeating',
  [LabelExtraFieldEnum.StarchRatio]: 'starchRatio',
  [LabelExtraFieldEnum.MilkNonfatSolidRatio]: 'milkNonfatSolidRatio',
  [LabelExtraFieldEnum.MilkFatRatio]: 'milkFatRatio',
  [LabelExtraFieldEnumSpec.MadeInCountry]: 'madeInCountry',
  [LabelExtraFieldEnumSpec.MadeInArea]: 'madeInArea',
  [LabelExtraFieldEnum.CustomValues]: undefined,
};
